import './Unify.css';

// Libraries
import React from 'react';

// Assets
import unifyImage from 'assets/images/okzou_unify_illustration.svg';

function Unify() {
    return (
        <div className="unify">
            <div className="content-container">
                <div className="unify-header">
                    <h2>
                        Unify <small>in development</small>
                    </h2>
                    <p>
                        Unify duplicated or multiple streams of data into one single source of
                        truth, using your own dynamic criteria.
                    </p>
                </div>
                <img src={unifyImage} />
            </div>
        </div>
    );
}

export default Unify;
