import { v4 as uuidv4 } from 'uuid';

class Filter {
    static height = 70;
    static bottomMargin = 10;
    static combineMiddleHeight = 10;
    constructor(
        tableProperty,
        operator,
        value,
        aggregateFunction = null,
        isNew = true,
        isPlaceholder = false,
        id = uuidv4()
    ) {
        this.tableProperty = tableProperty;
        this.operator = operator;
        this.value = value;
        this.id = id;
        this.isNew = isNew;
        this.isValid = true;
        this.aggregateFunction = aggregateFunction;
        this.isPlaceholder = isPlaceholder;
    }

    get filterType() {
        return this.tableProperty.type.type;
    }

    get isFilter() {
        return true;
    }

    get isFunctionSelected() {
        return this.aggregateFunction !== null && this.aggregateFunction !== '';
    }

    allowFunctions(primaryTableId) {
        return (
            primaryTableId !== this.tableProperty.table.global_id &&
            (this.filterType === 'Number' || this.filterType === 'Date')
        );
    }

    validate() {
        this.isValid = true;
        if (this.operator.displayValue) {
            this.isValid = this.value != null && String(this.value) != '';
        }
        return this.isValid;
    }
}

export default Filter;
