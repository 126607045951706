import './KpiBuilder.css';

// Libraries
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Button from 'components/Buttons/Button/Button';
import Alert from 'components/Alert/Alert';
import SideMenu from 'components/SideMenu/SideMenu';
import CardList from 'components/CardList/CardList';
import Accordion from 'components/Accordion/Accordion';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import Input from 'components/Form/Input/Input';
import LineChartData from 'components/SegmentationCharts/models/lineChartData';
import BarChart from 'components/SegmentationCharts/BarChart/BarChart';
import EditableHighlightField from 'components/EditableHighlightField/EditableHighlightField';
import Modal from 'components/Modal/Modal';
import InputError from 'components/Form/InputError/InputError';
import PieChart from 'components/SegmentationCharts/PieChart/PieChart';
import BubbleChart from 'components/SegmentationCharts/BubbleChart/BubbleChart';
import MatrixChart from 'components/SegmentationCharts/MatrixChart/MatrixChart';
import SingleValue from 'components/SegmentationCharts/SingleValue/SingleValue';
import Loader from 'components/Loader/Loader';

// Assets
import { ReactComponent as BarChartIcon } from 'assets/icons/bar_chart_icon_24.svg';
import { ReactComponent as GroupedBarChartIcon } from 'assets/icons/grouped_bar_chart_icon_24.svg';
import { ReactComponent as PieChartIcon } from 'assets/icons/pie_chart_icon_24.svg';
import { ReactComponent as BubbleChartIcon } from 'assets/icons/bubble_chart_icon_24.svg';
import { ReactComponent as MatrixChartIcon } from 'assets/icons/matrix_chart_icon_24.svg';
import { ReactComponent as OneIcon } from 'assets/icons/one_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

// Variables
import { FilterFunctions } from 'views/Segmentation/models/definitions';
import { singleValueDisplayTypes, visualTypes } from './definitions';

// Utils & hooks
import { generateRandomString } from 'utils/text.util';
import {
    useQuery,
    GET_TABLES_AND_PROPERTIES,
    useLazyQuery,
    PREVIEW_KPI,
    useMutation,
    CREATE_KPI,
    GET_KPI,
    UPDATE_KPI,
    GET_SEGMENT_JOIN_TABLE,
} from 'utils/graphql';
import { accentColorRgb, generateSimilarColors, convertToRgbaString } from 'utils/color.util';
import { useNavCollapse } from 'contexts/NavCollapseContext';
import useScreenResize from 'hooks/useScreenResize';

const KpiBuilder = ({}) => {
    let navigate = useNavigate();
    const [selectedVisualStyle, setSelectedVisualStyle] = useState();
    const [error, setError] = useState();
    const [visualStyleSelected, setVisualStyleSelected] = useState(false);
    const [chart, setChart] = useState(null);
    const [chartTypeMenuOpen, setChartTypeMenuOpen] = useState(false);
    const [loadedKpiDimensions, setLoadedKpiDimensions] = useState(false);
    const [finishedLoadingKpi, setFinishedLoadingKpi] = useState(false);
    const [firstSetupComplete, setFirstSetupComplete] = useState(false);

    const [primaryTableId, setPrimaryTableId] = useState();
    const [tables, setTables] = useState([]);
    const [joinTables, setJoinTables] = useState([]);
    const [graphDimensions, setGraphDimensions] = useState([]);
    const [kpiName, setKpiName] = useState('');
    const [chartTitle, setChartTitle] = useState('');
    const [kpiNameError, setKpiNameError] = useState('');
    const [dimensionErrors, setDimensionErrors] = useState([]);

    const [xAxisTable, setXAxisTable] = useState();
    const [xAxisProperties, setXAxisProperties] = useState([]);
    const [xAxisProperty, setXAxisProperty] = useState();
    const [xAxisShowAggregateDropdown, setXAxisShowAggregateDropdown] = useState(false);
    const [xAxisAggregateFunction, setXAxisAggregateFunction] = useState();
    const [xAxisDisplayName, setXAxisDisplayName] = useState('');
    const [xAxisTitle, setXAxisTitle] = useState('');
    const [xAxisShowPartitionsField, setXAxisShowPartitionsField] = useState(false);
    const [xAxisNumberOfPartitions, setXAxisNumberOfPartitions] = useState(4);
    const [xAxisPreviewName, setXAxisPreviewName] = useState(null);

    const [groupedByTable, setGroupedByTable] = useState();
    const [groupedByProperties, setGroupedByProperties] = useState([]);
    const [groupedByProperty, setGroupedByProperty] = useState();
    const [groupedByDisplayName, setGroupedByDisplayName] = useState('');
    const [groupedByPreviewName, setGroupedByPreviewName] = useState(null);

    const [displayAs, setDisplayAs] = useState(singleValueDisplayTypes.INT);
    const [useDenominator, setUseDenominator] = useState(false);

    const [yAxisTable, setYAxisTable] = useState();
    const [yAxisProperties, setYAxisProperties] = useState([]);
    const [yAxisProperty, setYAxisProperty] = useState();
    const [yAxisShowAggregateDropdown, setYAxisShowAggregateDropdown] = useState(false);
    const [yAxisAggregateFunction, setYAxisAggregateFunction] = useState();
    const [yAxisDisplayName, setYAxisDisplayName] = useState('');
    const [yAxisTitle, setYAxisTitle] = useState('');
    const [yAxisShowPartitionsField, setYAxisShowPartitionsField] = useState(false);
    const [yAxisNumberOfPartitions, setYAxisNumberOfPartitions] = useState(4);
    const [yAxisPreviewName, setYAxisPreviewName] = useState(null);

    const [zAxisTable, setZAxisTable] = useState();
    const [zAxisProperties, setZAxisProperties] = useState([]);
    const [zAxisProperty, setZAxisProperty] = useState();
    const [zAxisShowAggregateDropdown, setZAxisShowAggregateDropdown] = useState(false);
    const [zAxisAggregateFunction, setZAxisAggregateFunction] = useState();
    const [zAxisDisplayName, setZAxisDisplayName] = useState('');
    const [zAxisTitle, setZAxisTitle] = useState('');
    const [zAxisShowPartitionsField, setZAxisShowPartitionsField] = useState(false);
    const [zAxisNumberOfPartitions, setZAxisNumberOfPartitions] = useState(4);
    const [zAxisPreviewName, setZAxisPreviewName] = useState(null);

    const [chartData, setChartData] = useState(null);
    const [chartWidth, setChartWidth] = useState('100%');
    const [isSaving, setIsSaving] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState();

    const [isNavMenuResizing, setIsNavMenuResizing] = useState(false);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [chartPreviewWidth, setChartPreviewWidth] = useState('calc(100% - 300px)');
    const [chartPreviewTop, setChartPreviewTop] = useState(0);
    const [chartPreviewLeftPos, setChartPreviewLeftPos] = useState();
    const previewRef = useRef(null);
    const chartRef = useRef(null);
    const navCollapsed = useNavCollapse();

    const handleScrollAndResize = () => {
        if (!previewRef.current) return;
        const chartPreviewBreakPoint =
            window.scrollY + previewRef.current.getBoundingClientRect().top;
        // checking if break point is hit (top of chart preview element)
        if (window.scrollY >= chartPreviewBreakPoint) {
            setChartPreviewTop(-previewRef.current.getBoundingClientRect().top + 10);
        } else {
            setChartPreviewTop(0);
        }
        updatePreviewWidthAndPosition();
    };
    const kpiBuilderRef = useScreenResize(handleScrollAndResize);

    let { kpi_id } = useParams();
    if (kpi_id === 'new') {
        kpi_id = null;
    }

    const visualizationStyles = [
        {
            id: visualTypes.BAR,
            name: 'Bar Chart',
            icon: BarChartIcon,
        },
        {
            id: visualTypes.GROUPED_BAR,
            name: 'Grouped Bar Chart',
            icon: GroupedBarChartIcon,
        },
        {
            id: visualTypes.PIE,
            name: 'Pie Chart',
            icon: PieChartIcon,
        },
        {
            id: visualTypes.BUBBLE,
            name: 'Bubble Chart',
            icon: BubbleChartIcon,
        },
        {
            id: visualTypes.MATRIX,
            name: 'Matrix Chart',
            icon: MatrixChartIcon,
        },
        {
            id: visualTypes.SINGLE_VALUE,
            name: 'Single Value',
            icon: OneIcon,
        },
    ];

    const { data: kpiData } = useQuery(GET_KPI, {
        skip: !kpi_id,
        variables: { id: kpi_id },
    });

    // Load join tables associated with primary table
    const { data: joinTablesData, loading: loadingJoinTables } = useQuery(GET_SEGMENT_JOIN_TABLE, {
        skip: !primaryTableId,
        variables: { tableId: primaryTableId },
    });

    const { loading: loadingTables } = useQuery(GET_TABLES_AND_PROPERTIES, {
        onCompleted: (results) => {
            setTables(
                results?.tableSchemaList?.edges.map((edge) => ({
                    ...edge.node,
                    properties: edge.node.properties.edges.map((edge) => edge.node),
                }))
            );
        },
    });

    const [previewKpi] = useLazyQuery(PREVIEW_KPI, {
        fetchPolicy: 'no-cache',
        onError: (error) => {
            console.log(error);
        },
    });

    const [createKpi] = useMutation(CREATE_KPI);
    const [updateKpi] = useMutation(UPDATE_KPI);

    const nextStep = () => {
        setError(null);
        if (!selectedVisualStyle) {
            setError('Select a visualization style before proceeding to the next step.');
            return;
        }
        setVisualStyleSelected(true);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'createSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>New kpi created.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'createError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while creating the kpi.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            case 'updateSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>Kpi updated.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'updateError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while updating the kpi.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const validate = () => {
        setKpiNameError('');
        let valid = true;
        if (!kpiName || kpiName.length < 3) {
            setKpiNameError('Kpi name must be at least 3 characters.');
            valid = false;
        }
        const dimensionsAreValid = validateDimensions();
        return valid && dimensionsAreValid;
    };

    const validateDimensions = () => {
        let errors = [];
        switch (selectedVisualStyle.id) {
            case visualTypes.BAR:
            case visualTypes.GROUPED_BAR:
            case visualTypes.PIE:
                if (xAxisAggregateFunction && yAxisAggregateFunction) {
                    errors.push(
                        'Cannot set both the x axis and y axis aggregate functions for this chart type.'
                    );
                }
                const _property = getSelectedTableProperty(yAxisTable, yAxisProperty, true);
                if (
                    !(
                        _property.type.type === 'Number' ||
                        (yAxisAggregateFunction && _property.type.type !== 'Date')
                    )
                ) {
                    errors.push('Y axis must display numeric values.');
                }
                break;
        }
        setDimensionErrors(errors);
        return errors.length === 0;
    };

    const save = async () => {
        setIsSaving(true);
        if (!validate()) {
            setIsSaving(false);
            return;
        }
        const _xTable = getSelectedTable(xAxisTable, true, true);
        const _xProperty = getSelectedTableProperty(xAxisTable, xAxisProperty, true, true);
        const _yTable = getSelectedTable(yAxisTable, true);
        const _yProperty = getSelectedTableProperty(yAxisTable, yAxisProperty, true);
        const graphData = {};

        if (_xTable && _xProperty) {
            graphData.x_axis = {
                table: _xTable.id,
                property: _xProperty.id,
                aggregateFunction: xAxisAggregateFunction ? xAxisAggregateFunction : null,
                alias: xAxisTitle ? xAxisTitle : `${_xTable.name} ${_xProperty.property}`,
                ntile: xAxisShowPartitionsField ? xAxisNumberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
            };
            if (
                (visualStyleSelected.id === visualTypes.BAR ||
                    visualStyleSelected.id === visualTypes.PIE) &&
                _xTable.id === yAxisTable &&
                yAxisAggregateFunction
            ) {
                graphData.x_axis.groupedBy = true;
            }
        }

        if (
            selectedVisualStyle.id !== visualTypes.SINGLE_VALUE ||
            (selectedVisualStyle.id === visualTypes.SINGLE_VALUE && useDenominator)
        ) {
            graphData.y_axis = {
                table: yAxisTable,
                property: yAxisProperty,
                aggregateFunction: yAxisAggregateFunction ? yAxisAggregateFunction : null,
                alias: yAxisTitle ? yAxisTitle : `${_yTable.name} ${_yProperty.property}`,
                ntile: yAxisShowPartitionsField ? yAxisNumberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
            };
            if (
                (visualStyleSelected.id === visualTypes.BAR ||
                    visualStyleSelected.id === visualTypes.PIE) &&
                _xTable.id === yAxisTable &&
                xAxisAggregateFunction
            ) {
                graphData.y_axis.groupedBy = true;
            }
        }

        if (selectedVisualStyle.id === visualTypes.GROUPED_BAR) {
            const _groupedByTable = getSelectedTable(groupedByTable, true);
            const _groupedByProperty = getSelectedTableProperty(
                groupedByTable,
                groupedByProperty,
                true
            );
            graphData.grouped_by = {
                table: groupedByTable,
                property: groupedByProperty,
                aggregateFunction: null,
                alias: groupedByDisplayName
                    ? groupedByDisplayName
                    : `${_groupedByTable.name} ${_groupedByProperty.property}`,
                groupedBy: true,
            };
        }

        if (
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE
        ) {
            const _zTable = getSelectedTable(zAxisTable, true);
            const _zProperty = getSelectedTableProperty(zAxisTable, zAxisProperty, true);
            const _zPropertyName = _zProperty ? _zProperty.property : zAxisProperty;
            graphData.z_axis = {
                table: zAxisTable,
                property: zAxisProperty,
                aggregateFunction: zAxisAggregateFunction ? zAxisAggregateFunction : null,
                alias: zAxisDisplayName ? zAxisDisplayName : `${_zTable.name} ${_zPropertyName}`,
                ntile: zAxisShowPartitionsField ? zAxisNumberOfPartitions : null,
            };
            graphData.grouped_by = {
                table: groupedByTable,
            };
        }

        if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
            graphData.displayType = {
                displayAs,
            };
        }

        // updating
        if (kpi_id) {
            const { data, error } = await updateKpi({
                variables: {
                    kpi: {
                        id: kpi_id,
                        name: chartTitle,
                        graph: selectedVisualStyle.id,
                        data: JSON.stringify(graphData),
                    },
                },
            });
            setIsSaving(false);
            if (!error && data.updateKpi) {
                openModal('updateSuccess');
            } else {
                openModal('updateError');
            }
        } else {
            const { data, error } = await createKpi({
                variables: {
                    kpi: {
                        name: chartTitle,
                        graph: selectedVisualStyle.id,
                        data: JSON.stringify(graphData),
                    },
                },
            });
            setIsSaving(false);
            if (!error && data.createKpi) {
                openModal('createSuccess');
                setTimeout(() => {
                    navigate(`/kpis/` + data.createKpi.kpi.id);
                }, 1000);
            } else {
                openModal('createError');
            }
        }
    };

    const getLivePreviewData = async () => {
        if (!validateDimensions()) return;
        if (xAxisTable && xAxisProperty) {
            setIsLoadingPreview(true);
            const _xTable = getSelectedTable(xAxisTable, true, true);
            const _xProperty = getSelectedTableProperty(xAxisTable, xAxisProperty, true, true);
            const _xAliasName = xAxisTitle
                ? xAxisTitle
                : `${_xTable.name} ${_xProperty ? _xProperty.property : xAxisProperty}`;

            const columns =
                selectedVisualStyle.id === visualTypes.MATRIX ||
                selectedVisualStyle.id === visualTypes.BUBBLE
                    ? [
                          {
                              tableId: groupedByTable,
                          },
                      ]
                    : [];

            let groupByXAxis = false;
            let groupByYAxis = false;
            if (
                selectedVisualStyle.id === visualTypes.BAR ||
                selectedVisualStyle.id === visualTypes.PIE
            ) {
                if (_xTable.id === yAxisTable && yAxisAggregateFunction) {
                    groupByXAxis = true;
                } else if (_xTable.id === yAxisTable && xAxisAggregateFunction) {
                    groupByYAxis = true;
                }
            }

            columns.push({
                tableId: _xTable.id,
                propertyId: _xProperty.id,
                aggregateFunction: xAxisAggregateFunction ? xAxisAggregateFunction : null,
                groupedBy: groupByXAxis,
                alias: _xAliasName,
                ntile: xAxisShowPartitionsField ? xAxisNumberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
            });

            if (
                selectedVisualStyle.id !== visualTypes.SINGLE_VALUE ||
                (selectedVisualStyle.id === visualTypes.SINGLE_VALUE && useDenominator)
            ) {
                const _yTable = getSelectedTable(yAxisTable, true);
                const _yProperty = getSelectedTableProperty(yAxisTable, yAxisProperty, true);
                const _yAliasName = yAxisTitle
                    ? yAxisTitle
                    : `${_yTable.name} ${_yProperty ? _yProperty.property : yAxisProperty}`;
                columns.push({
                    tableId: yAxisTable,
                    propertyId: yAxisProperty,
                    aggregateFunction: yAxisAggregateFunction ? yAxisAggregateFunction : null,
                    groupedBy: groupByYAxis,
                    alias: _yAliasName,
                    ntile: yAxisShowPartitionsField ? yAxisNumberOfPartitions : null,
                    distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
                });
                setYAxisPreviewName(_yAliasName);
            }
            setXAxisPreviewName(_xAliasName);
            if (selectedVisualStyle.id === visualTypes.GROUPED_BAR) {
                const _groupedByTable = getSelectedTable(groupedByTable, true);
                const _groupedByProperty = getSelectedTableProperty(
                    groupedByTable,
                    groupedByProperty,
                    true
                );
                const _groupedByAliasName = groupedByDisplayName
                    ? groupedByDisplayName
                    : `${_groupedByTable.name} ${
                          _groupedByProperty ? _groupedByProperty.property : groupedByProperty
                      }`;
                columns.push({
                    tableId: groupedByTable,
                    propertyId: groupedByProperty,
                    groupedBy: true,
                    aggregateFunction: null,
                    alias: _groupedByAliasName,
                });
                setGroupedByPreviewName(_groupedByAliasName);
            }
            if (
                selectedVisualStyle.id === visualTypes.MATRIX ||
                selectedVisualStyle.id === visualTypes.BUBBLE
            ) {
                const _zTable = getSelectedTable(zAxisTable, true);
                const _zProperty = getSelectedTableProperty(zAxisTable, zAxisProperty, true);
                const _zAliasName = zAxisTitle
                    ? zAxisTitle
                    : `${_zTable.name} ${_zProperty ? _zProperty.property : zAxisProperty}`;
                columns.push({
                    tableId: zAxisTable,
                    propertyId: zAxisProperty,
                    aggregateFunction: zAxisAggregateFunction ? zAxisAggregateFunction : null,
                    alias: _zAliasName,
                    ntile: zAxisShowPartitionsField ? zAxisNumberOfPartitions : null,
                });
                setZAxisPreviewName(_zAliasName);
            }

            const { data, error } = await previewKpi({
                variables: {
                    columns,
                    limit: selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? null : 50,
                    graph: selectedVisualStyle.id,
                },
            });
            setChartData(data.kpiPreview.results);
            setIsLoadingPreview(false);
        }
    };

    const setupChartPreview = async () => {
        setChartWidth('100%');
        const _xTable = getSelectedTable(xAxisTable);
        const _xProperty = getSelectedTableProperty(xAxisTable, xAxisProperty);
        const _xPropertyName = _xProperty ? _xProperty.property : xAxisProperty;
        const _yTable = getSelectedTable(yAxisTable, true);
        const _yProperty = getSelectedTableProperty(yAxisTable, yAxisProperty, true);
        const _yPropertyName = _yProperty ? _yProperty.property : yAxisProperty;
        const _zTable = getSelectedTable(zAxisTable, true);
        const _zProperty = getSelectedTableProperty(zAxisTable, zAxisProperty, true);
        const _zPropertyName = _zProperty ? _zProperty.property : zAxisProperty;

        const _xAxisTitle = xAxisTitle ? xAxisTitle : `${_xTable.name} ${_xPropertyName}`;
        const _yAxisTitle = yAxisTitle ? yAxisTitle : `${_yTable.name} ${_yPropertyName}`;
        const _zAxisTitle = zAxisTitle ? zAxisTitle : `${_zTable.name} ${_zPropertyName}`;
        switch (selectedVisualStyle.id) {
            case visualTypes.BAR:
                let _xLabels, _yData;
                if (chartData) {
                    _xLabels = chartData.map((data) => data[xAxisPreviewName]);
                    _yData = chartData.map((data) => data[yAxisPreviewName]);
                } else {
                    _xLabels = Array.from({ length: 40 }, () => generateRandomString(5));
                    _yData = Array.from({ length: 40 }, () => Math.floor(Math.random() * 40));
                }
                setChart(
                    <BarChart
                        innerRef={chartRef}
                        title={chartTitle ? chartTitle : 'Bar Chart Preview'}
                        data={
                            new LineChartData(_xLabels, [
                                {
                                    label: yAxisTitle ? yAxisTitle : 'Y Axis Title',
                                    data: _yData,
                                    borderColor: convertToRgbaString(accentColorRgb),
                                    backgroundColor: convertToRgbaString(accentColorRgb, 0.8),
                                    borderWidth: 1,
                                },
                            ])
                        }
                        xAxisTitle={_xAxisTitle}
                        yAxisTitle={_yAxisTitle}
                    />
                );
                break;
            case visualTypes.GROUPED_BAR:
                let primaryLabels = [];
                let groupByLabels = [];
                const resultsDictionary = {};

                if (!chartData) {
                    primaryLabels = Array.from({ length: 20 }, () => generateRandomString(5));
                    groupByLabels = Array.from({ length: 5 }, () => generateRandomString(5));
                    for (let label of primaryLabels) {
                        for (let groupByLabel of groupByLabels) {
                            if (resultsDictionary[label]) {
                                resultsDictionary[label][groupByLabel] = Math.floor(
                                    Math.random() * 40
                                );
                            } else {
                                resultsDictionary[label] = {
                                    [groupByLabel]: Math.floor(Math.random() * 40),
                                };
                            }
                        }
                    }
                } else {
                    for (const result of chartData) {
                        const groupByLabel = result[groupedByPreviewName];
                        const label = result[xAxisPreviewName];
                        if (resultsDictionary[label]) {
                            resultsDictionary[label][groupByLabel] = result[yAxisPreviewName];
                        } else {
                            resultsDictionary[label] = {
                                [groupByLabel]: result[yAxisPreviewName],
                            };
                        }
                        if (!groupByLabels.includes(groupByLabel)) {
                            groupByLabels.push(groupByLabel);
                        }
                    }
                    primaryLabels = Object.keys(resultsDictionary);
                }
                const bgColors = generateSimilarColors(groupByLabels.length);
                setChart(
                    <BarChart
                        innerRef={chartRef}
                        title={chartTitle ? chartTitle : 'Grouped Bar Chart Preview'}
                        data={
                            new LineChartData(
                                primaryLabels,
                                groupByLabels.map((label, i) => {
                                    const data = [];
                                    for (let resultKey in resultsDictionary) {
                                        let resultValue = resultsDictionary[resultKey];
                                        data.push(resultValue[label] || 0);
                                    }
                                    return {
                                        label: label,
                                        data: data,
                                        borderColor: convertToRgbaString(accentColorRgb),
                                        backgroundColor: bgColors[i],
                                    };
                                })
                            )
                        }
                        xAxisTitle={_xAxisTitle}
                        yAxisTitle={_yAxisTitle}
                    />
                );
                break;
            case visualTypes.PIE:
                setChartWidth('750px');
                let _labels, _data;
                if (chartData) {
                    _labels = chartData.map((data) => data[xAxisPreviewName]);
                    _data = chartData.map((data) => data[yAxisPreviewName]);
                } else {
                    _labels = Array.from({ length: 5 }, () => generateRandomString(5));
                    _data = Array.from({ length: 5 }, () => Math.floor(Math.random() * 40));
                }
                setChart(
                    <PieChart
                        innerRef={chartRef}
                        title={chartTitle ? chartTitle : 'Pie Chart Preview'}
                        data={{
                            labels: _labels,
                            datasets: [
                                {
                                    label: yAxisTitle
                                        ? yAxisTitle
                                        : `${_yTable.name} ${_yProperty.property}`,
                                    data: _data,
                                    backgroundColor: generateSimilarColors(_data.length),
                                    borderColor: _data.map((d) =>
                                        convertToRgbaString(accentColorRgb)
                                    ),
                                    borderWidth: 1,
                                },
                            ],
                        }}
                    />
                );
                break;
            case visualTypes.BUBBLE:
                let bubbleData = [];
                if (chartData) {
                    for (const result of chartData) {
                        const existingDataPoint = bubbleData.find(
                            (dataPoint) =>
                                dataPoint.x === result[xAxisPreviewName] &&
                                dataPoint.y === result[yAxisPreviewName]
                        );
                        if (existingDataPoint) {
                            existingDataPoint.r =
                                parseInt(existingDataPoint.r) + parseInt(result[zAxisPreviewName]);
                        } else {
                            bubbleData.push({
                                x: result[xAxisPreviewName],
                                y: result[yAxisPreviewName],
                                r: result[zAxisPreviewName],
                            });
                        }
                    }
                } else {
                    for (let i = 1; i <= 30; i++) {
                        const x = Math.floor(Math.random() * xAxisNumberOfPartitions) + 1;
                        const y = Math.floor(Math.random() * yAxisNumberOfPartitions) + 1;
                        const r = Math.floor(Math.random() * zAxisNumberOfPartitions) + 1;
                        const existingDataPoint = bubbleData.find(
                            (bubbleData) => bubbleData.x === x && bubbleData.y === y
                        );
                        if (existingDataPoint) {
                            existingDataPoint.r = parseInt(existingDataPoint.r) + parseInt(r);
                        } else {
                            bubbleData.push({ x, y, r });
                        }
                    }
                }
                setChart(
                    <BubbleChart
                        innerRef={chartRef}
                        title={chartTitle ? chartTitle : 'Bubble Chart Preview'}
                        datasets={[
                            {
                                label: 'z-axis',
                                data: bubbleData,
                                borderColor: convertToRgbaString(accentColorRgb),
                                backgroundColor: convertToRgbaString(accentColorRgb, 0.8),
                            },
                        ]}
                        xAxisTitle={_xAxisTitle}
                        yAxisTitle={_yAxisTitle}
                        zAxisTitle={_zAxisTitle}
                    />
                );
                break;
            case visualTypes.MATRIX:
                let _dataSet = [];
                let _xAxisLabels, _yAxisLabels;

                if (chartData) {
                    for (const result of chartData) {
                        const existingDataPoint = _dataSet.find(
                            (dataPoint) =>
                                dataPoint.x === result[xAxisPreviewName] &&
                                dataPoint.y === result[yAxisPreviewName]
                        );
                        if (existingDataPoint) {
                            existingDataPoint.z =
                                parseInt(existingDataPoint.z) + parseInt(result[zAxisPreviewName]);
                        } else {
                            _dataSet.push({
                                x: result[xAxisPreviewName],
                                y: result[yAxisPreviewName],
                                z: result[zAxisPreviewName],
                            });
                        }
                    }
                } else {
                    for (let i = 1; i <= 100; i++) {
                        const x = Math.floor(Math.random() * xAxisNumberOfPartitions) + 1;
                        const y = Math.floor(Math.random() * yAxisNumberOfPartitions) + 1;
                        const z = Math.floor(Math.random() * zAxisNumberOfPartitions) + 1;
                        const existingDataPoint = _dataSet.find(
                            (dataPoint) =>
                                dataPoint.x.toString() === x.toString() &&
                                dataPoint.y.toString() === y.toString()
                        );
                        if (existingDataPoint) {
                            existingDataPoint.z = (
                                parseInt(existingDataPoint.z) + parseInt(z)
                            ).toString();
                        } else {
                            _dataSet.push({ x: x.toString(), y: y.toString(), z: z.toString() });
                        }
                    }
                }
                _xAxisLabels = Array.from({ length: xAxisNumberOfPartitions }, (_, i) =>
                    (i + 1).toString()
                );
                _yAxisLabels = Array.from({ length: yAxisNumberOfPartitions }, (_, i) =>
                    (i + 1).toString()
                );

                setChart(
                    <MatrixChart
                        innerRef={chartRef}
                        title={chartTitle ? chartTitle : 'Matrix Chart Preview'}
                        datasets={[
                            {
                                data: _dataSet,
                            },
                        ]}
                        xAxisTitle={_xAxisTitle}
                        yAxisTitle={_yAxisTitle}
                        zAxisTitle={_zAxisTitle}
                        xAxisLabels={_xAxisLabels}
                        yAxisLabels={_yAxisLabels}
                        backgroundRgbBaseColor={accentColorRgb}
                    />
                );
                break;
            case visualTypes.SINGLE_VALUE:
                let numerator = Math.floor(Math.random() * 300);
                let denominator =
                    displayAs === singleValueDisplayTypes.FRACTION
                        ? Math.floor(Math.random() * 1000)
                        : 1;
                if (chartData) {
                    numerator = chartData[0][_xAxisTitle];
                    denominator = useDenominator ? chartData[0][_yAxisTitle] : 1;
                }
                setChart(
                    <SingleValue
                        title={chartTitle ? chartTitle : 'Single Value Preview'}
                        numerator={parseFloat(numerator)}
                        denominator={parseFloat(denominator)}
                        displayType={displayAs}
                    />
                );
                break;
        }
        if (!firstSetupComplete) setFirstSetupComplete(true);
    };

    const loadGraphDimensions = (graphType, graphData) => {
        // setting up X axis properties
        const xAxis = graphData.x_axis;
        if (xAxis) {
            const _xAxisTable = getSelectedTable(xAxis.table, true);
            setXAxisTable(_xAxisTable.global_id);
            setXAxisProperties(
                getTableProperties(
                    _xAxisTable.global_id,
                    false,
                    graphType === visualTypes.SINGLE_VALUE
                )
            );
            if (xAxis.property === 'id') {
                setXAxisProperty('id');
            } else {
                const _xAxisProperty = getSelectedTableProperty(
                    _xAxisTable.id,
                    xAxis.property,
                    true
                );
                setXAxisProperty(_xAxisProperty.global_id);
            }
            setXAxisDisplayName(xAxis.alias);
            setXAxisTitle(xAxis.alias);
            setXAxisShowAggregateDropdown(
                xAxis.property === 'id'
                    ? true
                    : isTablePropertyNumericOrDate(xAxis.table, xAxis.property, true)
            );
            if (xAxis.aggregateFunction) {
                setXAxisAggregateFunction(xAxis.aggregateFunction);
            }
        }

        // setting up Y axis properties
        const yAxis = graphData.y_axis;
        if (yAxis) {
            setYAxisTable(yAxis.table);
            setYAxisProperties(
                getTableProperties(yAxis.table, true, graphType === visualTypes.SINGLE_VALUE)
            );
            setYAxisProperty(yAxis.property);
            setYAxisDisplayName(yAxis.alias);
            setYAxisTitle(yAxis.alias);
            setYAxisShowAggregateDropdown(
                yAxis.property === 'id'
                    ? true
                    : isTablePropertyNumericOrDate(yAxis.table, yAxis.property, true)
            );
            if (yAxis.aggregateFunction) {
                setYAxisAggregateFunction(yAxis.aggregateFunction);
            }

            if (graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE) {
                setXAxisShowPartitionsField(true);
                setXAxisNumberOfPartitions(xAxis.ntile);
                setYAxisShowPartitionsField(true);
                setYAxisNumberOfPartitions(yAxis.ntile);
            }

            if (graphType === visualTypes.SINGLE_VALUE) {
                setUseDenominator(true);
            }
        }

        if (graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE) {
            // setting up Z axis properties
            const zAxis = graphData.z_axis;
            setZAxisTable(zAxis.table);
            setZAxisProperties(getTableProperties(zAxis.table, true));
            setZAxisProperty(zAxis.property);
            setZAxisDisplayName(zAxis.alias);
            setZAxisTitle(zAxis.alias);
            setZAxisShowPartitionsField(true);
            setZAxisNumberOfPartitions(zAxis.ntile);
            setZAxisShowAggregateDropdown(
                zAxis.property === 'id'
                    ? true
                    : isTablePropertyNumericOrDate(zAxis.table, zAxis.property, true)
            );
            if (zAxis.aggregateFunction) {
                setZAxisAggregateFunction(zAxis.aggregateFunction);
            }

            // setting up grouped by table
            setGroupedByTable(graphData.grouped_by.table);
        }

        if (graphType === visualTypes.GROUPED_BAR) {
            // setting up grouped by properties
            const groupedBy = graphData.grouped_by;
            setGroupedByTable(groupedBy.table);
            setGroupedByProperties(getTableProperties(groupedBy.table, true));
            setGroupedByProperty(groupedBy.property);
            setGroupedByDisplayName(groupedBy.alias);
        }

        if (graphType === visualTypes.SINGLE_VALUE) {
            setDisplayAs(graphData.displayType.displayAs);
        }
    };

    const getXAxisDimensionComponent = () => {
        return (
            <>
                <p>Select Table</p>
                <Dropdown
                    value={xAxisTable}
                    values={tables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => {
                        setXAxisTable(e.target.value);
                    }}
                />
                <p>Select Table Property</p>
                <Dropdown
                    value={xAxisProperty}
                    values={xAxisProperties}
                    setValue={(e) => {
                        setXAxisProperty(e.target.value);
                    }}
                />
                {xAxisShowAggregateDropdown && (
                    <>
                        <p>Select Aggregate Function</p>
                        <Dropdown
                            value={xAxisAggregateFunction}
                            values={getAggregateFunctions(xAxisTable, xAxisProperty)}
                            setValue={(e) => {
                                setXAxisAggregateFunction(e.target.value);
                            }}
                        />
                    </>
                )}
                {xAxisShowPartitionsField && (
                    <>
                        <p>Number of Partitions (ntile)</p>
                        <Input
                            value={xAxisNumberOfPartitions}
                            setValue={(e) => {
                                setXAxisNumberOfPartitions(e.target.value);
                            }}
                            type="number"
                            placeholder="Number of Partitions"
                            width="100%"
                        />
                    </>
                )}
                <p>Display Name</p>
                <Input
                    value={xAxisDisplayName}
                    setValue={(e) => {
                        setXAxisDisplayName(e.target.value);
                    }}
                    type="text"
                    placeholder="Display Name"
                    width="100%"
                />
            </>
        );
    };

    const getYAxisDimensionComponent = () => {
        return (
            <>
                <p>Select Table</p>
                <Dropdown
                    value={yAxisTable}
                    values={joinTables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => setYAxisTable(e.target.value)}
                />
                <p>Select Table Property</p>
                <Dropdown
                    value={yAxisProperty}
                    values={yAxisProperties}
                    setValue={(e) => {
                        setYAxisProperty(e.target.value);
                    }}
                />
                {yAxisShowAggregateDropdown && (
                    <>
                        <p>Select Aggregate Function</p>
                        <Dropdown
                            value={yAxisAggregateFunction}
                            values={getAggregateFunctions(yAxisTable, yAxisProperty, true)}
                            setValue={(e) => {
                                setYAxisAggregateFunction(e.target.value);
                            }}
                        />
                    </>
                )}
                {yAxisShowPartitionsField && (
                    <>
                        <p>Number of Partitions (ntile)</p>
                        <Input
                            value={yAxisNumberOfPartitions}
                            setValue={(e) => {
                                setYAxisNumberOfPartitions(e.target.value);
                            }}
                            type="number"
                            placeholder="Number of Partitions"
                            width="100%"
                        />
                    </>
                )}
                <p>Display Name</p>
                <Input
                    value={yAxisDisplayName}
                    setValue={(e) => {
                        setYAxisDisplayName(e.target.value);
                    }}
                    type="text"
                    placeholder="Display Name"
                    width="100%"
                />
            </>
        );
    };

    const getZAxisDimensionComponent = () => {
        return (
            <>
                <p>Select Table</p>
                <Dropdown
                    value={zAxisTable}
                    values={joinTables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => setZAxisTable(e.target.value)}
                />
                <p>Select Table Property</p>
                <Dropdown
                    value={zAxisProperty}
                    values={zAxisProperties}
                    setValue={(e) => {
                        setZAxisProperty(e.target.value);
                    }}
                />
                {zAxisShowAggregateDropdown && (
                    <>
                        <p>Select Aggregate Function</p>
                        <Dropdown
                            value={zAxisAggregateFunction}
                            values={getAggregateFunctions(zAxisTable, zAxisProperty, true)}
                            setValue={(e) => {
                                setZAxisAggregateFunction(e.target.value);
                            }}
                        />
                    </>
                )}
                {zAxisShowPartitionsField && (
                    <>
                        <p>Number of Partitions (ntile)</p>
                        <Input
                            value={zAxisNumberOfPartitions}
                            setValue={(e) => {
                                setZAxisNumberOfPartitions(e.target.value);
                            }}
                            type="number"
                            placeholder="Number of Partitions"
                            width="100%"
                        />
                    </>
                )}
                <p>Display Name</p>
                <Input
                    value={zAxisDisplayName}
                    setValue={(e) => {
                        setZAxisDisplayName(e.target.value);
                    }}
                    type="text"
                    placeholder="Display Name"
                    width="100%"
                />
            </>
        );
    };

    const getGroupedByDimensionsComponent = (displayTableOnly = false) => {
        return (
            <>
                <p>Select Table</p>
                <Dropdown
                    value={groupedByTable}
                    values={joinTables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => setGroupedByTable(e.target.value)}
                />
                {!displayTableOnly && (
                    <>
                        <p>Select Table Property</p>
                        <Dropdown
                            value={groupedByProperty}
                            values={groupedByProperties}
                            setValue={(e) => setGroupedByProperty(e.target.value)}
                        />
                        <p>Display Name</p>
                        <Input
                            value={groupedByDisplayName}
                            setValue={(e) => setGroupedByDisplayName(e.target.value)}
                            type="text"
                            placeholder="Display Name"
                            width="100%"
                        />
                    </>
                )}
            </>
        );
    };

    const getSingleValueTypeDimensionsComponent = () => {
        return (
            <>
                <p>Display As</p>
                <Dropdown
                    value={displayAs}
                    values={[
                        {
                            value: singleValueDisplayTypes.INT,
                            text: 'Integer',
                        },
                        {
                            value: singleValueDisplayTypes.DOLLAR,
                            text: 'Dollar Amount',
                        },
                        {
                            value: singleValueDisplayTypes.FRACTION,
                            text: 'Fraction',
                        },
                        {
                            value: singleValueDisplayTypes.PERCENT,
                            text: 'Percentage',
                        },
                    ]}
                    setValue={(e) => setDisplayAs(e.target.value)}
                />
            </>
        );
    };

    const getSingleValueNumeratorDimensionsComponent = () => {
        return (
            <>
                <p>Select Table</p>
                <Dropdown
                    value={xAxisTable}
                    values={tables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => {
                        setXAxisTable(e.target.value);
                    }}
                />
                <p>Select Table Property</p>
                <Dropdown
                    value={xAxisProperty}
                    values={xAxisProperties}
                    setValue={(e) => {
                        setXAxisProperty(e.target.value);
                    }}
                />
                <p>Select Aggregate Function</p>
                <Dropdown
                    value={xAxisAggregateFunction}
                    values={getAggregateFunctions(xAxisTable, xAxisProperty)}
                    setValue={(e) => {
                        setXAxisAggregateFunction(e.target.value);
                    }}
                />
            </>
        );
    };

    const getSingleValueDenominatorDimensionsComponent = () => {
        return (
            <>
                <div className="use-denominator">
                    Use Denominator{' '}
                    <Input
                        type="checkbox"
                        width="45px"
                        minWidth="45px"
                        value={useDenominator}
                        setValue={(e) => {
                            setUseDenominator(e.target.checked);
                        }}
                    />
                </div>
                {useDenominator && (
                    <>
                        <p>Select Table</p>
                        <Dropdown
                            value={yAxisTable}
                            values={joinTables.map((table) => ({
                                value: table.id,
                                text: table.name,
                            }))}
                            setValue={(e) => setYAxisTable(e.target.value)}
                        />
                        <p>Select Table Property</p>
                        <Dropdown
                            value={yAxisProperty}
                            values={yAxisProperties}
                            setValue={(e) => {
                                setYAxisProperty(e.target.value);
                            }}
                        />
                        <p>Select Aggregate Function</p>
                        <Dropdown
                            value={yAxisAggregateFunction}
                            values={getAggregateFunctions(yAxisTable, yAxisProperty, true)}
                            setValue={(e) => {
                                setYAxisAggregateFunction(e.target.value);
                            }}
                        />
                    </>
                )}
            </>
        );
    };

    const setupGraphDimensions = () => {
        let _graphDimensions = [];
        switch (selectedVisualStyle.id) {
            case visualTypes.BAR:
            case visualTypes.PIE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getXAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getYAxisDimensionComponent(),
                    },
                ];
                break;
            case visualTypes.GROUPED_BAR:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getXAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'X Axis - Grouped By',
                        body: getGroupedByDimensionsComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getYAxisDimensionComponent(),
                    },
                ];
                break;
            case visualTypes.MATRIX:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getXAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getYAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Z Axis',
                        body: getZAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Grouped By',
                        body: getGroupedByDimensionsComponent(true),
                    },
                ];
                break;
            case visualTypes.BUBBLE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getXAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getYAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Radius',
                        body: getZAxisDimensionComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Grouped By',
                        body: getGroupedByDimensionsComponent(true),
                    },
                ];
                break;
            case visualTypes.SINGLE_VALUE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'Display Type',
                        body: getSingleValueTypeDimensionsComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Numerator Value',
                        body: getSingleValueNumeratorDimensionsComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Denominator Value',
                        body: getSingleValueDenominatorDimensionsComponent(),
                    },
                ];
        }
        setGraphDimensions(_graphDimensions);
    };

    const getAggregateFunctions = (tableId, propertyId, isJoinTable = false) => {
        if (propertyId === 'id') {
            if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
                return [{ text: 'COUNT', value: 'count' }];
            }
            return [
                { text: 'None', value: '' },
                { text: 'COUNT', value: 'count' },
            ];
        }
        const property = getSelectedTableProperty(tableId, propertyId, isJoinTable);
        if (!property) return [];
        const dataType = property.type.type;
        let filterFunctions = FilterFunctions;
        if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
            filterFunctions.filter((filterFunc) => filterFunc.text !== 'None');
        }
        if (dataType === 'Number') {
            return filterFunctions;
        } else if (dataType === 'Date') {
            return filterFunctions.filter(
                (filterFunc) => filterFunc.text !== 'SUM' && filterFunc.text !== 'AVG'
            );
        }
        return [];
    };

    const getSelectedTable = (tableId, isJoinTable = false, isGlobalId = false) => {
        const _tables = isJoinTable ? joinTables : tables;
        if (isGlobalId) return _tables.find((table) => table.global_id === tableId);
        return _tables.find((table) => table.id === tableId);
    };

    const getSelectedTableProperty = (
        tableId,
        propertyId,
        isJoinTable = false,
        isGlobalId = false
    ) => {
        if (propertyId === 'id') {
            return {
                id: 'id',
                property: 'id',
                type: {
                    type: 'UUID',
                },
            };
        }
        const _table = getSelectedTable(tableId, isJoinTable, isGlobalId);
        if (!_table) return null;
        if (isGlobalId)
            return _table.properties.find((property) => property.global_id === propertyId);
        return _table.properties.find((property) => property.id === propertyId);
    };

    const getTableProperties = (tableId, isJoinTable = false, onlyNumericProperties = false) => {
        const _table = getSelectedTable(tableId, isJoinTable);
        if (!_table) return [];
        let properties = [{ value: 'id', text: 'id' }];
        if (onlyNumericProperties) {
            return properties.concat(
                _table.properties
                    .filter((tp) => tp.type.type === 'Number')
                    .map((property) => ({
                        value: property.id,
                        text: property.property,
                    }))
            );
        } else {
            return properties.concat(
                _table.properties.map((property) => ({
                    value: property.id,
                    text: property.property,
                }))
            );
        }
    };

    const isTablePropertyNumericOrDate = (tableId, propertyId, isJoinTable = false) => {
        const _property = getSelectedTableProperty(tableId, propertyId, isJoinTable);
        return _property && (_property.type.type === 'Number' || _property.type.type === 'Date');
    };

    const updatePreviewWidthAndPosition = () => {
        if (!previewRef.current) return;
        setChartPreviewWidth(previewRef.current.getBoundingClientRect().width);
        setChartPreviewLeftPos(previewRef.current.getBoundingClientRect().left);
    };

    // x axis useEffects
    useEffect(() => {
        if (!xAxisTable || (kpi_id && !finishedLoadingKpi)) return;
        setPrimaryTableId(xAxisTable);
        const _properties = getTableProperties(
            xAxisTable,
            false,
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE
        );
        setXAxisProperties(_properties);
        setXAxisProperty(_properties[0].value);
        setXAxisAggregateFunction(
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : ''
        );
    }, [xAxisTable]);

    useEffect(() => {
        if (!xAxisProperty || (kpi_id && !finishedLoadingKpi)) return;
        setXAxisShowAggregateDropdown(
            xAxisProperty === 'id' ? true : isTablePropertyNumericOrDate(xAxisTable, xAxisProperty)
        );
        setXAxisAggregateFunction(
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : ''
        );
    }, [xAxisProperty]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setXAxisTitle(xAxisDisplayName), 500);
        return () => clearTimeout(timeOutId);
    }, [xAxisDisplayName]);

    // grouped by useEffects
    useEffect(() => {
        if (!groupedByTable || (kpi_id && !finishedLoadingKpi)) return;
        const properties = getTableProperties(groupedByTable, true);
        setGroupedByProperties(properties);
        setGroupedByProperty(properties[0].value);
    }, [groupedByTable]);

    // y axis useEffects
    useEffect(() => {
        if (!yAxisTable || (kpi_id && !finishedLoadingKpi)) return;
        const properties = getTableProperties(
            yAxisTable,
            true,
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE
        );
        setYAxisProperties(properties);
        setYAxisProperty(properties[0].value);
        setYAxisAggregateFunction(
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : ''
        );
    }, [yAxisTable]);

    useEffect(() => {
        if (!yAxisProperty || (kpi_id && !finishedLoadingKpi)) return;
        setYAxisShowAggregateDropdown(
            yAxisProperty === 'id'
                ? true
                : isTablePropertyNumericOrDate(yAxisTable, yAxisProperty, true)
        );
        setYAxisAggregateFunction(
            selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : ''
        );
    }, [yAxisProperty]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setYAxisTitle(yAxisDisplayName), 500);
        return () => clearTimeout(timeOutId);
    }, [yAxisDisplayName]);

    // z axis useEffects
    useEffect(() => {
        if (!zAxisTable || (kpi_id && !finishedLoadingKpi)) return;
        const properties = getTableProperties(zAxisTable, true);
        setZAxisProperties(properties);
        setZAxisProperty(properties[0].value);
        setZAxisAggregateFunction('');
    }, [zAxisTable]);

    useEffect(() => {
        if (!zAxisProperty || (kpi_id && !finishedLoadingKpi)) return;
        setZAxisShowAggregateDropdown(
            zAxisProperty === 'id'
                ? true
                : isTablePropertyNumericOrDate(zAxisTable, zAxisProperty, true)
        );
        setZAxisAggregateFunction('');
    }, [zAxisProperty]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setZAxisTitle(zAxisDisplayName), 500);
        return () => clearTimeout(timeOutId);
    }, [zAxisDisplayName]);

    useEffect(() => {
        if (!joinTablesData || !selectedVisualStyle) return;

        const tables = joinTablesData.segmentationJoinTableList;
        setJoinTables(tables);
        // setting default table values
        setYAxisTable(tables[0].id);
        setZAxisTable(tables[0].id);
        setGroupedByTable(tables[0].id);
    }, [joinTablesData, selectedVisualStyle]);

    useEffect(() => {
        if (!tables || tables.length === 0 || !selectedVisualStyle) return;
        // setting default table for x axis
        setXAxisTable(tables[0].id);
    }, [tables, selectedVisualStyle]);

    useEffect(() => {
        if (
            !joinTables ||
            joinTables.length === 0 ||
            !kpi_id ||
            loadedKpiDimensions ||
            !kpiData ||
            !kpiData.kpi
        )
            return;
        loadGraphDimensions(kpiData.kpi.graph, JSON.parse(kpiData.kpi.data));
        setLoadedKpiDimensions(true);
    }, [joinTables]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setChartTitle(kpiName), 500);
        return () => clearTimeout(timeOutId);
    }, [kpiName]);

    useEffect(() => {
        if (!selectedVisualStyle) return;
        const showPartitionFields =
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE;
        setXAxisShowPartitionsField(showPartitionFields);
        setYAxisShowPartitionsField(showPartitionFields);
        setZAxisShowPartitionsField(showPartitionFields);
    }, [selectedVisualStyle]);

    useEffect(() => {
        if (!selectedVisualStyle) return;
        setupGraphDimensions();
    }, [
        selectedVisualStyle,
        xAxisTable,
        xAxisProperties,
        xAxisProperty,
        xAxisShowAggregateDropdown,
        xAxisAggregateFunction,
        xAxisDisplayName,
        xAxisShowPartitionsField,
        xAxisNumberOfPartitions,
        yAxisTable,
        yAxisProperties,
        yAxisProperty,
        yAxisShowAggregateDropdown,
        yAxisAggregateFunction,
        yAxisDisplayName,
        yAxisShowPartitionsField,
        yAxisNumberOfPartitions,
        zAxisTable,
        zAxisProperties,
        zAxisProperty,
        zAxisShowAggregateDropdown,
        zAxisAggregateFunction,
        zAxisDisplayName,
        zAxisShowPartitionsField,
        zAxisNumberOfPartitions,
        groupedByTable,
        groupedByProperties,
        groupedByProperty,
        groupedByDisplayName,
        displayAs,
        useDenominator,
    ]);

    useEffect(() => {
        if (!selectedVisualStyle || !xAxisTable || !yAxisTable || !zAxisTable) return;
        setupChartPreview();
    }, [
        selectedVisualStyle,
        xAxisTitle,
        yAxisTitle,
        zAxisTitle,
        chartData,
        chartTitle,
        xAxisTable,
        yAxisTable,
        zAxisTable,
        displayAs,
    ]);

    useEffect(() => {
        if (!loadedKpiDimensions) return;
        const loadPreviewData = async () => {
            await getLivePreviewData();
            setFinishedLoadingKpi(true);
        };
        loadPreviewData();
    }, [loadedKpiDimensions]);

    // loading existing kpi
    useEffect(() => {
        if (!kpiData?.kpi || !tables || tables.length === 0) return;
        const kpi = kpiData.kpi;
        const graphData = JSON.parse(kpi.data);
        setSelectedVisualStyle(visualizationStyles.find((style) => style.id === kpi.graph));
        setVisualStyleSelected(true);
        setPrimaryTableId(graphData.x_axis.table);
        setKpiName(kpi.name);
        setChartTitle(kpi.name);
    }, [kpiData, tables]);

    useEffect(() => {
        if (!visualStyleSelected || !previewRef.current) return;
        window.addEventListener('scroll', handleScrollAndResize, { passive: true });
        // clean up the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScrollAndResize);
        };
    }, [visualStyleSelected]);

    useEffect(() => {
        if (!visualStyleSelected || !previewRef.current) return;
        setIsNavMenuResizing(true);
        const timeOutId = setTimeout(() => {
            updatePreviewWidthAndPosition();
            setIsNavMenuResizing(false);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [navCollapsed]);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.resize();
        }
    }, [chartPreviewWidth]);

    useEffect(() => {
        if (kpi_id) setVisualStyleSelected(true);
    }, [kpi_id]);

    return (
        <div className="kpi-builder" ref={kpiBuilderRef}>
            <ContentPanel
                title={
                    <div className="kpi-builder-header">
                        <div className="header-col">
                            KPI Builder
                            <div className="sub-header">
                                {!visualStyleSelected
                                    ? 'Choose a Visualization Style'
                                    : 'Customize Visualization'}
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="kpi-builder-body">
                    {error && (
                        <Alert variant="error" width="100%">
                            {error}
                        </Alert>
                    )}
                    {!visualStyleSelected ? (
                        <div className="visualization-style">
                            <div className="chart-cards">
                                {visualizationStyles.map((style, i) => (
                                    <div
                                        key={i}
                                        onClick={() => setSelectedVisualStyle(style)}
                                        className={`chart-card ${
                                            selectedVisualStyle?.name === style.name
                                                ? 'selected'
                                                : ''
                                        }`}
                                    >
                                        <style.icon className="icon" />
                                        {style.name}
                                    </div>
                                ))}
                            </div>
                            <Button className="next-btn" onClick={nextStep}>
                                Next
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className="kpi-customization">
                                {graphDimensions && graphDimensions.length > 0 && (
                                    <div className="dimensions-sidemenu">
                                        <SideMenu header="Dimensions" width="100%">
                                            <Accordion
                                                padding="10px 20px"
                                                items={graphDimensions}
                                            />
                                        </SideMenu>
                                    </div>
                                )}
                                <div className="chart-preview-container" ref={previewRef}>
                                    {isNavMenuResizing ||
                                    loadingTables ||
                                    loadingJoinTables ||
                                    (kpi_id && !finishedLoadingKpi) ? (
                                        <div className="chart-preview-loader">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: chartPreviewWidth,
                                                top: chartPreviewTop,
                                                left: chartPreviewLeftPos,
                                            }}
                                            className="chart-preview"
                                        >
                                            <div className="chart-type-row">
                                                <div className="kpi-name-input">
                                                    <EditableHighlightField
                                                        defaultDisplayName="Kpi Name"
                                                        placeholder="Kpi Name"
                                                        value={kpiName}
                                                        setValue={(value) => setKpiName(value)}
                                                    />
                                                    <InputError error={kpiNameError} />
                                                </div>
                                                <div className="chart-type-btn">
                                                    <Button
                                                        width="150px"
                                                        variant="secondary"
                                                        onClick={() => setChartTypeMenuOpen(true)}
                                                    >
                                                        Chart Type
                                                        <selectedVisualStyle.icon className="chart-type-icon" />
                                                    </Button>
                                                    <CardList
                                                        items={visualizationStyles}
                                                        onClickOutside={() =>
                                                            setChartTypeMenuOpen(false)
                                                        }
                                                        onClickItemCallback={(item) => {
                                                            setSelectedVisualStyle(item);
                                                            setChartTypeMenuOpen(false);
                                                        }}
                                                        getCardItemContent={(item) => item.name}
                                                        show={chartTypeMenuOpen}
                                                        width="100%"
                                                        top="50px"
                                                        left="0"
                                                        right="0"
                                                        showDivider="all"
                                                    />
                                                </div>
                                            </div>
                                            {dimensionErrors.length > 0 && (
                                                <div className="dimension-errors">
                                                    {dimensionErrors.map((error, i) => (
                                                        <Alert variant="error" key={i} width="100%">
                                                            {error}
                                                        </Alert>
                                                    ))}
                                                </div>
                                            )}
                                            <div
                                                className="chart-container"
                                                style={{ maxWidth: chartWidth }}
                                            >
                                                {chart}
                                            </div>
                                            <div className="action-btns">
                                                <Button
                                                    className="preview-btn"
                                                    variant="secondary"
                                                    width="150px"
                                                    onClick={getLivePreviewData}
                                                    isLoading={isLoadingPreview}
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    width="150px"
                                                    onClick={save}
                                                    isLoading={isSaving}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </ContentPanel>
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setModalIsOpen} />
        </div>
    );
};

export default KpiBuilder;
