export const sortByProperty = (key) => (a, b) => {
    if (!key) {
        throw 'key argument must be provided';
    }

    const aValue = getChildProperty(a, key);
    const bValue = getChildProperty(b, key);
    if (aValue < bValue) {
        return -1;
    }
    if (aValue > bValue) {
        return 1;
    }
    return 0;
};

export const sortByProperties = (key1, key2) => (a, b) => {
    if (!key1 || !key2) {
        throw 'Key arguments must be provided';
    }
    const aValue = getChildProperty(a, key1);
    const bValue = getChildProperty(b, key1);
    if (aValue === bValue) {
        return sortByProperty(key2)(a, b);
    }
    return sortByProperty(key1)(a, b);
};

const getChildProperty = (object, property) => {
    const propertyArray = property.split('.');
    while (propertyArray.length && (object = object[propertyArray.shift()]));
    return object;
};
