import './App.css';

// Libraries
import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApolloProvider, client } from 'utils/graphql/apollo';

// Utils
import User from 'utils/user.util';

// Component & Views
import useToken from 'components/useToken';
import Header from 'components/Header/Header';
import Login from 'views/Login/Login';
import Dashboard from 'views/Dashboard/Dashboard';
import Account from 'views/Account/Account';
import Apps from 'views/Apps/Apps';
import { default as UserApp } from 'views/Apps/App/App';
import { default as AppsImport } from 'views/Apps/Import/Import';
import Database from 'views/Database/Database';
import Table from 'views/Database/Table/Table';
import Import from 'views/Import/Import';
import Consent from 'views/Consent/Consent';
import Unify from 'views/Unify/Unify';
import Segmentation from 'views/Segmentation/Segmentation';
import Logout from 'views/Logout/Logout';
import ContentWrapper from 'components/ContentWrapper/ContentWrapper';
import Settings from 'views/Settings/Settings';
import Segment from 'views/Segmentation/Segment/Segment';
import Builder from 'views/Apps/Builder/Builder';
import ObjectEditor from 'views/Database/ObjectEditor/ObjectEditor';
import TokenRefresh from 'components/Auth/TokenRefresh';
import Flow from 'views/Segmentation/Flow/Flow';
import Download from 'views/Segmentation/Download/Download';
import ComponentsDemo from 'views/ComponentsDemo/ComponentsDemo';
import KpiBuilder from 'views/Kpis/KpiBuilder/KpiBuilder';
import AccessWrapper from 'components/AccessWrapper/AccessWrapper';
import Kpis from 'views/Kpis/Kpis';

export const PermissionsContext = createContext();

export const RoutesList = [
    {
        path: '/',
        element: <Dashboard />,
    },
    {
        path: '/account',
        element: <Account />,
    },
    {
        path: '/apps',
        element: <Apps />,
    },
    {
        path: '/apps/new',
        element: <Builder />,
    },
    {
        path: '/apps/edit/:app_id',
        element: <Builder />,
    },
    {
        path: '/apps/:app',
        element: <UserApp />,
    },
    {
        path: '/apps/import',
        element: <AppsImport />,
    },
    {
        path: '/consent',
        element: <Consent />,
    },
    {
        path: '/consent/:segment_id',
        element: <Segment />,
    },
    {
        path: '/database',
        element: <Database />,
    },
    {
        path: '/database/new',
        element: <ObjectEditor />,
    },
    {
        path: '/database/edit/:table_id',
        element: <ObjectEditor />,
    },
    {
        path: '/database/:table',
        element: <Table />,
    },
    {
        path: '/import',
        element: <Import />,
    },
    {
        path: '/segmentation',
        element: <Segmentation />,
    },
    {
        path: '/segmentation/flow/new/:segment_id',
        element: <Flow />,
    },
    {
        path: '/segmentation/flow/:flow_id/:segment_id',
        element: <Flow />,
    },
    {
        path: '/segmentation/download/:segment_id',
        element: <Download />,
    },
    {
        path: '/segmentation/:segment_id',
        element: <Segment />,
    },
    {
        path: '/unify',
        element: <Unify />,
    },
    {
        path: '/kpis',
        element: <Kpis />,
    },
    {
        path: '/kpis/:kpi_id',
        element: <KpiBuilder />,
    },
    {
        path: '/settings/*',
        element: <Settings />,
    },
    {
        path: '/demo',
        element: <ComponentsDemo />,
    },
];

function App() {
    const { token, setToken, isAuth } = useToken();
    const [permissions, setPermissions] = useState();

    const getPermissions = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/permission`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    setPermissions(data);
                }
            })
            .catch((err) => console.log(err));
    };

    /**
     * When permissions are not yet set and the user
     * is logged in, fetch permission set from backend.
     **/
    useEffect(() => {
        if (permissions || !token) return;
        getPermissions();
    }, [permissions, token]);

    /**
     * Logout and clear all user storage data when token
     * is no longer valid.
     **/
    if (!token) {
        User.clear();
        return <Login setToken={setToken} />;
    }

    // Check if user is authenticated on each render
    isAuth()
        .then((auth) => {
            if (!auth) {
                setToken(null);
            }
        })
        .catch(() => {
            setToken(null);
        });

    return (
        <div className="app">
            <TokenRefresh>
                <PermissionsContext.Provider value={{ permissions }}>
                    <Header />
                    <ContentWrapper>
                        <ApolloProvider client={client}>
                            <AccessWrapper>
                                <Routes>
                                    {RoutesList.map((_route, i) => {
                                        return (
                                            <Route
                                                key={i}
                                                path={_route.path}
                                                element={_route.element}
                                            ></Route>
                                        );
                                    })}
                                    <Route
                                        path="/logout"
                                        element={<Logout setToken={setToken} />}
                                    ></Route>
                                </Routes>
                            </AccessWrapper>
                        </ApolloProvider>
                    </ContentWrapper>
                </PermissionsContext.Provider>
            </TokenRefresh>
        </div>
    );
}

export default App;
