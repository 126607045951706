import './FilterGroup.css';

// Libraries
import { useEffect, useRef, useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import update from 'immutability-helper';
import { getEmptyImage } from 'react-dnd-html5-backend';

// Components
import Filter from '../Filter/Filter';
import Tooltip from 'components/Tooltip/Tooltip';

// Hooks
import useTimeout from 'hooks/useTimeout';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/remove_icon.svg';

// Variables & Models
import { DraggableItemTypes, Operators } from 'views/Segmentation/models/definitions';
import { default as FilterModel } from 'views/Segmentation/models/filter';
import { default as FilterGroupModel } from 'views/Segmentation/models/filterGroup';

const acceptableTypes = Object.values(DraggableItemTypes);

const FilterGroup = ({
    index = 0,
    filterGroup,
    filterGroupParent = null,
    updateFilterGroup,
    isValid = true,
    callUpdateFilterGroupOnHover,
    triggerClearAllPlaceholders,
    isCombinePreviewActive,
    setIsCombinePreviewActive,
    isDraggingPlaceholder = false,
    placeholderWidth = null,
    primaryTableId,
}) => {
    const filterHeight = FilterModel.height;
    const filterCombineMiddleHeight = FilterModel.combineMiddleHeight;
    const filterBottomMargin = FilterModel.bottomMargin;

    const filterGroupRef = useRef(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [combineFromIndex, setCombineFromIndex] = useState(null);
    const [combineToIndex, setCombineToIndex] = useState(null);
    const [combineHoverActive, setCombineHoverActive] = useState(false);
    const [width, setWidth] = useState(0);

    const [{}, drop] = useDrop({
        accept: acceptableTypes,
        drop: (item, monitor) => {
            // checking if the drop event has already been handled
            if (monitor.didDrop()) return;
            dropHandler(item);
        },
        canDrop: (item) => {
            // can drop if type is accepted, current filter group is not combining and
            // if dropped item is of type filterGroup, cannot drop inside itself
            return (
                acceptableTypes.includes(item.type) &&
                !filterGroup.isCombining &&
                !(
                    item.type === DraggableItemTypes.FILTERGROUP &&
                    filterGroup.id === item.data.filter.id
                )
            );
        },
        hover: (item, monitor) => {
            if (!filterGroupRef.current || filterGroup.hasBusyChild) {
                return;
            }
            if (
                item.type === DraggableItemTypes.FILTERGROUP &&
                filterGroup.id === item.data.filter.id
            ) {
                convertFilterGroupToPlaceholder();
                return;
            }
            if (
                monitor.isOver({ shallow: true }) &&
                monitor.canDrop() &&
                !filterGroup.isCombining
            ) {
                // Determine filterGroup rectangle bounds on screen
                const filterGroupBoundingRect = filterGroupRef.current?.getBoundingClientRect();
                // Determine mouse position
                const mousePosition = monitor.getClientOffset();
                const filterGroupTop = filterGroupBoundingRect.top;

                if (item.type === DraggableItemTypes.PROPERTY) {
                    handlePropertyHover(filterGroupTop, mousePosition.y, item);
                } else {
                    handleFilterHover(filterGroupTop, mousePosition.y, item);
                }
            }
        },
    });

    const [{}, drag, preview] = useDrag(
        {
            type: DraggableItemTypes.FILTERGROUP,
            item: {
                index,
                type: DraggableItemTypes.FILTERGROUP,
                data: { filter: filterGroup, parent: filterGroupParent },
                width,
            },
            end: (item, monitor) => {
                // drop happened outside any filter group, reverting back filters to same order
                if (!monitor.didDrop()) {
                    updateCurrentFilterGroup({ isPlaceholder: false });
                }
            },
        },
        [filterGroup, filterGroupParent, width]
    );

    if (filterGroup.isRoot) {
        drop(filterGroupRef);
    } else {
        drag(drop(filterGroupRef));
    }

    useTimeout(
        () => createCombinePlaceholderFilterGroup(combineFromIndex, combineToIndex),
        combineHoverActive ? 400 : null
    );

    const convertFilterGroupToPlaceholder = () => {
        if (!filterGroup.isPlaceholder) {
            updateCurrentFilterGroup({ isPlaceholder: true }, false);
        }
    };

    const handleFilterHover = (filterGroupTop, yMousePosition, item) => {
        const filterToDragIndex = filterGroup.filters.findIndex(
            (f) => f.id === item.data.filter.id
        );

        if (isCombinePreviewActive) {
            const childPlaceholderFilterGroupIndex = filterGroup.filters.findIndex(
                (f) => !f.isFilter && f.isPlaceholder
            );
            // while combining, user hovered outside of current filter group. This is not allowed!
            if (childPlaceholderFilterGroupIndex < 0) {
                return;
            }

            const { top: childFilterGroupTop, bottom: childFilterGroupBottom } =
                getFilterTopMiddleBottomBounds(childPlaceholderFilterGroupIndex, filterGroupTop);
            // checking if user has hovered out of the combine placeholder filter group
            if (yMousePosition < childFilterGroupTop || yMousePosition > childFilterGroupBottom) {
                const updatedFilters = update(filterGroup.filters, {
                    $splice: [
                        [
                            childPlaceholderFilterGroupIndex,
                            1,
                            ...setAllChildFilterGroupsIsCombiningProperty(
                                filterGroup.filters[childPlaceholderFilterGroupIndex].filters,
                                false
                            ),
                        ],
                    ],
                });
                updateCurrentFilterGroup({ filters: updatedFilters }, false);
                setIsCombinePreviewActive(false);
            }
        } // item was dragged out of or dragged into the current filter group
        else if (filterToDragIndex < 0) {
            const draggedFilter = item.data.parent.filters.find(
                (f) => f.id === item.data.filter.id
            );
            // only way this is possbile is
            // item was dragged from include to exclude or vice versa
            if (filterGroup.filterCount === 0) {
                updateCurrentFilterGroup({
                    filters: update(filterGroup.filters, {
                        $splice: [[0, 0, draggedFilter]],
                    }),
                });
            } else {
                let i = 0;
                while (i < filterGroup.filterCount) {
                    const { top, middleTop, middleBottom, bottom } = getFilterTopMiddleBottomBounds(
                        i,
                        filterGroupTop
                    );
                    if (
                        (filterGroup.filterCount === 1 && yMousePosition < middleTop) ||
                        (yMousePosition >= top && yMousePosition < middleTop)
                    ) {
                        updateCurrentFilterGroup({
                            filters: update(filterGroup.filters, {
                                $apply: (filters) => filterOutAllChildPlaceholders(filters),
                                $splice: [
                                    [
                                        i,
                                        0,
                                        update(draggedFilter, { isPlaceholder: { $set: true } }),
                                    ],
                                ],
                            }),
                            isBusyWithNewFilter: true,
                        });
                        return;
                    } else if (
                        (i === filterGroup.filterCount - 1 && yMousePosition >= middleBottom) ||
                        (yMousePosition >= middleBottom && yMousePosition <= bottom)
                    ) {
                        updateCurrentFilterGroup({
                            filters: update(filterGroup.filters, {
                                $apply: (filters) => filterOutAllChildPlaceholders(filters),
                                $splice: [
                                    [
                                        i + 1,
                                        0,
                                        update(draggedFilter, { isPlaceholder: { $set: true } }),
                                    ],
                                ],
                            }),
                        });
                        return;
                    }
                    i++;
                }
            }
        } // drag just started, convert dragged filter to placeholder
        else if (!filterGroup.filters[filterToDragIndex].isPlaceholder) {
            updateCurrentFilterGroup({
                filters: update(filterGroup.filters, {
                    [filterToDragIndex]: { isPlaceholder: { $set: true } },
                }),
            });
        } // re-ordering inside current filter group
        else {
            reorderFiltersInsideFilterGroup(filterToDragIndex, filterGroupTop, yMousePosition);
        }
    };

    const reorderFiltersInsideFilterGroup = (
        draggedFilterIndex,
        filterGroupTop,
        yMousePosition
    ) => {
        const { top: draggedFilterTop, bottom: draggedFilterBottom } =
            getFilterTopMiddleBottomBounds(draggedFilterIndex, filterGroupTop);

        // dragging up
        if (yMousePosition < draggedFilterTop) {
            let i = 0;
            while (i < draggedFilterIndex) {
                const { middleTop, middleBottom } = getFilterTopMiddleBottomBounds(
                    i,
                    filterGroupTop
                );
                // hovering over filter middle
                if (yMousePosition <= middleBottom && yMousePosition >= middleTop) {
                    combineFiltersOnHover(draggedFilterIndex, i);
                    return;
                } else if (yMousePosition < middleTop) {
                    setCombineHoverActive(false);
                    moveFilterFromToIndex(draggedFilterIndex, i);
                    return;
                }
                i++;
            }
        } // dragging down
        else if (yMousePosition > draggedFilterBottom) {
            let i = draggedFilterIndex + 1;
            while (i < filterGroup.filterCount) {
                const { middleTop, middleBottom } = getFilterTopMiddleBottomBounds(
                    i,
                    filterGroupTop
                );

                // hovering over filter middle
                if (yMousePosition >= middleTop && yMousePosition <= middleBottom) {
                    combineFiltersOnHover(i, draggedFilterIndex);
                    return;
                } else if (yMousePosition > middleBottom) {
                    setCombineHoverActive(false);
                    moveFilterFromToIndex(draggedFilterIndex, i);
                    return;
                }
                i++;
            }
        }
    };

    const handlePropertyHover = (filterGroupTop, yMousePosition, item) => {
        if (filterGroup.filterCount === 0) {
            addNewPlaceHolderFilterOnHover(item, 0);
        } else {
            const filterPlaceholderIndex = filterGroup.filters.findIndex((f) => f.isPlaceholder);
            if (isCombinePreviewActive) {
                const childPlaceholderFilterGroupIndex = filterGroup.filters.findIndex(
                    (f) => !f.isFilter && f.isPlaceholder
                );

                // while combining, user hovered outside of current filter group. This is not allowed!
                if (childPlaceholderFilterGroupIndex < 0) return;

                const { top: childFilterGroupTop, bottom: childFilterGroupBottom } =
                    getFilterTopMiddleBottomBounds(
                        childPlaceholderFilterGroupIndex,
                        filterGroupTop
                    );
                // checking if user has hovered out of the combine placeholder filter group
                if (
                    yMousePosition < childFilterGroupTop ||
                    yMousePosition > childFilterGroupBottom
                ) {
                    updateCurrentFilterGroup(
                        {
                            filters: update(filterGroup.filters, {
                                $splice: [
                                    [
                                        childPlaceholderFilterGroupIndex,
                                        1,
                                        ...setAllChildFilterGroupsIsCombiningProperty(
                                            filterGroup.filters[childPlaceholderFilterGroupIndex]
                                                .filters,
                                            false
                                        ),
                                    ],
                                ],
                            }),
                        },
                        false
                    );
                    setIsCombinePreviewActive(false);
                }
            } // new property got dragged in
            else if (filterPlaceholderIndex < 0) {
                let i = 0;
                while (i < filterGroup.filterCount) {
                    const { top, middleTop, middleBottom, bottom } = getFilterTopMiddleBottomBounds(
                        i,
                        filterGroupTop
                    );
                    if (
                        (filterGroup.filterCount === 1 && yMousePosition < middleTop) ||
                        (yMousePosition >= top && yMousePosition < middleTop)
                    ) {
                        addNewPlaceHolderFilterOnHover(item, i);
                        return;
                    } else if (
                        (i === filterGroup.filterCount - 1 && yMousePosition >= middleBottom) ||
                        (yMousePosition >= middleBottom && yMousePosition <= bottom)
                    ) {
                        addNewPlaceHolderFilterOnHover(item, i + 1);
                        return;
                    }
                    i++;
                }
            } // filter placeholder already exists, reordering
            else {
                reorderFiltersInsideFilterGroup(
                    filterPlaceholderIndex,
                    filterGroupTop,
                    yMousePosition
                );
            }
        }
    };

    const getFilterTopMiddleBottomBounds = (index, filterGroupTop) => {
        const currentFilter = filterGroup.filters[index];
        let filterTop = filterGroupTop + (filterGroup.isRoot ? 10 : 0);
        for (let i = 0; i < index; i++) {
            const childFilter = filterGroup.filters[i];
            if (childFilter.isFilter) {
                filterTop += filterHeight;
            } else {
                filterTop += childFilter.filterGroupHeight;
            }
        }
        if (index > 0) filterTop += index * filterBottomMargin;

        const currentFilterHeight = currentFilter.isFilter
            ? filterHeight
            : currentFilter.filterGroupHeight;

        const filterMiddleTop = filterTop + (currentFilterHeight - filterCombineMiddleHeight) / 2;
        const filterMiddleBottom = filterMiddleTop + filterCombineMiddleHeight;
        let filterBottom = filterTop + currentFilterHeight;
        if (index < filterGroup.filterCount - 1) filterBottom += filterBottomMargin;
        return {
            top: filterTop,
            middleTop: filterMiddleTop,
            middleBottom: filterMiddleBottom,
            bottom: filterBottom,
        };
    };

    const addNewPlaceHolderFilterOnHover = (item, toIndex) => {
        if (filterGroup.filters[toIndex]?.isPlaceholder) return;
        const newFilter = new FilterModel(
            item.data,
            Operators[item.data.type.type][0],
            null,
            null,
            true,
            true
        );
        if (item.data.type.type === 'Function') {
            newFilter.aggregateFunction = 'count';
        }
        const updatedFilters = update(filterGroup.filters, {
            $apply: (filters) => filterOutAllChildPlaceholders(filters),
            $splice: [[toIndex, 0, newFilter]], // add new filter at index
        });
        filterGroup.filters = updatedFilters;
        callUpdateFilterGroupOnHover && updateCurrentFilterGroup({ filters: updatedFilters });
    };

    const filterOutAllChildPlaceholders = (filters) => {
        return update(filters, {
            $apply: (filters) =>
                filters
                    .filter((filter) => !filter.isPlaceholder)
                    .map((filter) => {
                        // if child filterGroup, recursively filter out child placeholders
                        if (!filter.isFilter) {
                            return update(filter, {
                                filters: { $set: filterOutAllChildPlaceholders(filter.filters) },
                            });
                        }
                        return filter;
                    }),
        });
    };

    const createCombinePlaceholderFilterGroup = (fromIndex, toIndex) => {
        const toFilter = filterGroup.filters[toIndex].isFilter
            ? filterGroup.filters[toIndex]
            : update(filterGroup.filters[toIndex], {
                  isCombining: { $set: true },
                  filters: (filters) => setAllChildFilterGroupsIsCombiningProperty(filters),
              });
        const fromFilter = filterGroup.filters[fromIndex].isFilter
            ? filterGroup.filters[fromIndex]
            : update(filterGroup.filters[fromIndex], {
                  isCombining: { $set: true },
                  filters: (filters) => setAllChildFilterGroupsIsCombiningProperty(filters),
              });
        const placeholderFilterGroup = new FilterGroupModel(
            false,
            'AND',
            [toFilter, fromFilter],
            true,
            true,
            true
        );
        updateCurrentFilterGroup({
            filters: update(filterGroup.filters, {
                $splice: [
                    [fromIndex, 1],
                    [toIndex, 1],
                    [toIndex, 0, placeholderFilterGroup],
                ],
            }),
        });
        setCombineHoverActive(false);
        setIsCombinePreviewActive(true);
    };

    const combineFiltersOnHover = (fromIndex, toIndex) => {
        if (!combineHoverActive) {
            setCombineFromIndex(fromIndex);
            setCombineToIndex(toIndex);
            setCombineHoverActive(true);
        } else if (combineToIndex !== toIndex) {
            setCombineHoverActive(false);
        }
    };

    const moveFilterFromToIndex = (fromIndex, toIndex) => {
        updateCurrentFilterGroup({
            filters: update(filterGroup.filters, {
                $splice: [
                    [fromIndex, 1],
                    [toIndex, 0, filterGroup.filters[fromIndex]],
                ],
            }),
        });
    };

    const updateCurrentFilterGroup = (
        { id, operator, filters, isPlaceholder, isBusyWithNewFilter } = {},
        filterOutPlaceholders = true
    ) => {
        id = id || filterGroup.id;
        filters = filters || filterGroup.filters;
        operator = operator || filterGroup.operator;
        isPlaceholder =
            isPlaceholder !== undefined && isPlaceholder !== null
                ? isPlaceholder
                : filterGroup.isPlaceholder;
        isBusyWithNewFilter =
            isBusyWithNewFilter !== undefined && isBusyWithNewFilter !== null
                ? isBusyWithNewFilter
                : filterGroup.isBusyWithNewFilter;
        updateFilterGroup(
            { id, filters, operator, isPlaceholder, isBusyWithNewFilter },
            filterOutPlaceholders
        );
    };

    const updateChildFilterGroup = (
        { id, operator, filters, isPlaceholder, isBusyWithNewFilter },
        filterOutPlaceholders = true
    ) => {
        const childFilterGroupIndex = filterGroup.filters.findIndex((fg) => fg.id === id);
        if (childFilterGroupIndex >= 0) {
            // updating the child filter group, with the passed in properties
            const updatedChildFilterGroup = update(filterGroup.filters[childFilterGroupIndex], {
                operator: { $set: operator },
                filters: { $set: filters },
                isPlaceholder: { $set: isPlaceholder },
                isBusyWithNewFilter: { $set: isBusyWithNewFilter },
            });
            updateCurrentFilterGroup(
                {
                    filters: update(filterGroup.filters, {
                        $apply: (filters) =>
                            filters.filter((filter) =>
                                filterOutPlaceholders ? !filter.isPlaceholder : true
                            ), // filter out placeholders
                        [childFilterGroupIndex]: { $set: updatedChildFilterGroup },
                    }),
                },
                filterOutPlaceholders
            );
        } // child filter group got deleted
        else {
            updateCurrentFilterGroup({}, filterOutPlaceholders);
        }
    };

    const dropHandler = (item) => {
        setCombineHoverActive(false);
        setIsCombinePreviewActive(false);
        updateCurrentFilterGroup({
            filters: update(filterGroup.filters, (filters) => {
                return setAllChildFilterGroupsIsCombiningProperty(
                    setAllChildPlaceholdersToFalse(filters),
                    false
                );
            }),
        });
    };

    const setAllChildPlaceholdersToFalse = (filters) => {
        return update(filters, (filters) =>
            filters.map((filter) => {
                // if child filterGroup, recursively set all child filter placeholders to false
                if (!filter.isFilter) {
                    return update(filter, {
                        isPlaceholder: { $set: false },
                        filters: { $set: setAllChildPlaceholdersToFalse(filter.filters) },
                    });
                } else {
                    return update(filter, { isPlaceholder: { $set: false } });
                }
            })
        );
    };

    const setAllChildFilterGroupsIsCombiningProperty = (filters, isCombining = true) => {
        return update(filters, (filters) =>
            filters.map((filter) => {
                // if child is a filter group
                // update isCombining property and recursively do the same for it's children
                if (!filter.isFilter) {
                    return update(filter, {
                        isCombining: { $set: isCombining },
                        filters: {
                            $set: setAllChildFilterGroupsIsCombiningProperty(
                                filter.filters,
                                isCombining
                            ),
                        },
                    });
                }
                return filter;
            })
        );
    };

    const setOperator = (e) => {
        filterGroup.operator = e.target.value;
        updateCurrentFilterGroup();
    };

    const isFilterGroupValid = () => {
        return isValid && filterGroup.isValid;
    };

    const deleteFilterGroup = () => {
        const currentFilters = filterGroup.filters.splice(0, filterGroup.filterCount);
        const currentFilterGroupIndex = filterGroupParent.filters.findIndex(
            (f) => f.id === filterGroup.id
        );
        filterGroupParent.filters.splice(
            currentFilterGroupIndex,
            1,
            ...currentFilters.filter((f) => !f.isPlaceholder)
        );
        updateCurrentFilterGroup();
    };

    const operator = (
        <div
            className={`operator ${!filterGroup.isValid ? 'invalid' : ''}`}
            style={{ minHeight: `${FilterGroupModel.minimumHeight}px` }}
        >
            {!filterGroup.isRoot && (
                <RemoveIcon
                    className="remove"
                    fill="var(--error-color)"
                    onClick={deleteFilterGroup}
                />
            )}
            <p>Operator</p>
            <select
                className="segment-dropdown"
                defaultValue={filterGroup.operator}
                onChange={(e) => {
                    setOperator(e);
                }}
            >
                <option value="AND">AND</option>
                <option value="OR">OR</option>
            </select>
        </div>
    );

    useEffect(() => {
        if (filterGroup.isBusyWithNewFilter) {
            const timeOutId = setTimeout(() => {
                updateCurrentFilterGroup({
                    isBusyWithNewFilter: false,
                });
            }, 100);
            return () => clearTimeout(timeOutId);
        }
    }, [filterGroup.isBusyWithNewFilter]);

    useEffect(() => {
        if (firstLoad) return;
        // delete filter group if it is the root of where the combining started
        if (filterGroup.isCombining && filterGroup.isPlaceholder) {
            deleteFilterGroup();
        } else {
            updateCurrentFilterGroup({
                filters: update(filterGroup.filters, (filters) =>
                    filters
                        .filter((filter) => !filter.isPlaceholder) // filter out placeholders
                        .map((filter) => {
                            // if filterGroup, set isCombining property to false
                            if (!filter.isFilter) {
                                return update(filter, { isCombining: { $set: false } });
                            }
                            return filter;
                        })
                ),
            });
        }
    }, [triggerClearAllPlaceholders]);

    useEffect(() => {
        if (isDraggingPlaceholder) return;
        setFirstLoad(false);
        preview(getEmptyImage(), { captureDraggingState: true });
        if (!filterGroupRef.current) return;
        const timeOutId = setTimeout(() => {
            setWidth(filterGroupRef.current.getBoundingClientRect().width);
        }, 100);
        return () => clearTimeout(timeOutId);
    }, []);

    return (
        <div
            ref={filterGroupRef}
            style={{
                marginBottom: `${FilterModel.bottomMargin}px`,
                width: isDraggingPlaceholder ? `${placeholderWidth}px` : '100%',
            }}
            className={`filter-group ${filterGroup.isRoot ? 'root' : ''} 
            ${filterGroup.isPlaceholder ? 'placeholder' : ''} ${
                isDraggingPlaceholder ? 'is-dragging-placeholder' : ''
            }`}
        >
            <div className="filter-group-body">
                {filterGroup.displayOperator &&
                    (!filterGroup.isValid ? (
                        <Tooltip
                            width="200px"
                            tip={
                                filterGroup.isRoot
                                    ? 'Cannot just have 1 child filter group.'
                                    : 'Must have at least 2 child filters.'
                            }
                        >
                            {operator}
                        </Tooltip>
                    ) : (
                        operator
                    ))}
                <div className="filters">
                    {filterGroup.filters.map((item, i) => {
                        if (!item) return;
                        if (item.isFilter) {
                            return (
                                <Filter
                                    key={i}
                                    index={i}
                                    filter={item}
                                    filterGroup={filterGroup}
                                    updateFilterGroup={updateCurrentFilterGroup}
                                    setIsCombinePreviewActive={setIsCombinePreviewActive}
                                    primaryTableId={primaryTableId}
                                />
                            );
                        } else {
                            return (
                                <FilterGroup
                                    key={i}
                                    index={i}
                                    filterGroup={item}
                                    filterGroupParent={filterGroup}
                                    updateFilterGroup={updateChildFilterGroup}
                                    isValid={isFilterGroupValid()}
                                    callUpdateFilterGroupOnHover={callUpdateFilterGroupOnHover}
                                    triggerClearAllPlaceholders={triggerClearAllPlaceholders}
                                    isCombinePreviewActive={isCombinePreviewActive}
                                    setIsCombinePreviewActive={setIsCombinePreviewActive}
                                    primaryTableId={primaryTableId}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default FilterGroup;
