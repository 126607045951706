import Button from 'components/Buttons/Button/Button';
import './Modal.css';

// Libraries
import React from 'react';
import { default as ReactModal } from 'react-modal';

function Modal({ isOpen, setOpen, options = {} }) {
    const {
        type = '',
        title = 'title',
        content = '',
        footer = '',
        width = '400px',
        textAlign = 'left',
        confirmBtnText = 'Ok',
        cancelBtnText = 'Cancel',
        closeBtnAction = () => {},
        confirmationBtnAction = () => {},
    } = options;

    ReactModal.setAppElement('#root');

    const style = {
        overlay: {
            zIndex: '10',
        },
        content: {
            width: width,
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            textAlign: textAlign,
        },
    };

    return (
        <ReactModal isOpen={isOpen} style={style} onRequestClose={() => setOpen(false)}>
            <div className="modal">
                <h2>{title}</h2>
                <div className="modal-content">{content}</div>
                <>
                    {footer}
                    {type === 'confirmation' ? (
                        <div className="confirmation-btn-row">
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                {cancelBtnText}
                            </Button>
                            <Button
                                onClick={() => {
                                    const { cancel = false } = confirmationBtnAction() || {};
                                    if (!cancel) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {confirmBtnText}
                            </Button>
                        </div>
                    ) : (
                        <div className="info-btn-row">
                            <Button
                                onClick={() => {
                                    closeBtnAction();
                                    setOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    )}
                </>
            </div>
        </ReactModal>
    );
}

export default Modal;
