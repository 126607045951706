import './Search.css';

// Libraries
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Assets
import { ReactComponent as SearchIcon } from 'assets/icons/search_icon.svg';

const parseQueryParams = (search) => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get('query') || '';
};

const Search = React.memo(({ width = '250px', height = '45px', handler = () => {} }) => {
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        setSearchQuery(query);
    }, []);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            const query = e.target.value;
            window.history.replaceState(null, '', `?query=${encodeURIComponent(query)}`);
            handler(query);
        }
    };

    return (
        <div className="search-wrapper" style={{ width, height }}>
            <SearchIcon className="icon" fill="#575757" />
            <input
                placeholder="Search"
                value={searchQuery}
                onKeyUp={handleSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
                aria-label="Search"
            />
        </div>
    );
});

Search.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    handler: PropTypes.func,
};

export default Search;
