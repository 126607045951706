import './Connections.css';

// Libraries
import React, { useState } from 'react';

// Utils
import { useFetch } from 'utils/rest/request';
import User from 'utils/user.util';
import { appColors } from 'utils/app.util';

// Components
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Input from 'components/Form/Input/Input';
import { handleOAuth2 } from './OAuth/OAuth2';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-triangle.svg';

function Connections() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [removeConnectionApp, setRemoveConnectionApp] = useState();
    const [newConnectionApp, setNewConnectionApp] = useState();
    const [newConnectionName, setNewConnectionName] = useState();
    const [validationErrors, setValidationErrors] = useState();

    const {
        loading,
        data: apps,
        error,
    } = useFetch('/api/connected_apps', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const getModalOptions = (type) => {
        switch (type) {
            case 'already-connected':
                return {
                    title: 'Hold on a sec...',
                    content: (
                        <div>
                            <p>This app is already connected.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'input-error':
                return {
                    title: <ErrorIcon fill="var(--error-color)" />,
                    content: (
                        <div>
                            <p>Please fill in all fields to proceed.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'add-connection':
                const client_dependencies = newConnectionApp?.connection_dependencies.filter(
                    (e) => e.type === 'CLIENT'
                );
                return {
                    title: `New ${newConnectionApp.name} connection`,
                    type: 'confirmation',
                    content: (
                        <div>
                            <p>Connection Name</p>
                            <Input
                                placeholder="Name"
                                setValue={(e) => {
                                    setNewConnectionName(e.target.value);
                                }}
                                marginBottom="10px"
                                error={validationErrors?.newConnectionName}
                                displayError={true}
                            />
                            {client_dependencies.map((e, index) => {
                                return (
                                    <div key={index}>
                                        <p>{e.value}</p>
                                        <Input
                                            placeholder={e.field}
                                            setValue={(e) => {
                                                // @Todo
                                            }}
                                            marginBottom="10px"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ),
                    width: '350px',
                    textAlign: 'center',
                    confirmBtnText: 'Connect',
                    confirmationBtnAction: () => {
                        const existingNames = apps.rows.flatMap((app) =>
                            app.connections.map((connection) => connection.name)
                        );

                        // Form validation
                        if (!newConnectionName) {
                            setValidationErrors({
                                newConnectionName: 'Required connection name.',
                            });
                            return { cancel: true };
                        } else if (existingNames.includes(newConnectionName)) {
                            setValidationErrors({
                                newConnectionName: 'Name already exists and must be unique.',
                            });
                            return { cancel: true };
                        }

                        // Allow fow multiple types of authentication
                        switch (newConnectionApp.version) {
                            case 'oauth2.0':
                                return handleOAuth2(newConnectionApp, newConnectionName).then(
                                    (response) => {
                                        if (response?.error) {
                                            setModalType('unavailable');
                                            setIsOpen(true);
                                        }
                                    }
                                );
                            default:
                                return;
                        }
                    },
                };
            case 'remove-connection':
                return {
                    title: <WarningIcon fill="var(--error-color)" width="40" height="40" />,
                    type: 'confirmation',
                    content: (
                        <p>
                            Are you sure you want to remove '{removeConnectionApp.name}' connection?
                        </p>
                    ),
                    width: '300px',
                    textAlign: 'center',
                    confirmBtnText: 'Remove',
                    confirmationBtnAction: () => {
                        fetch(
                            `${process.env.REACT_APP_BACKEND_URL}/api/connected_apps/tenant/connections`,
                            {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${User.getToken()}`,
                                },
                                body: JSON.stringify({
                                    connection_id: removeConnectionApp.id,
                                }),
                            }
                        )
                            .then((response) => response.json())
                            .then((data) => {
                                if (data?.deleted == 1) {
                                    window.location.reload();
                                }
                            })
                            .catch((err) => console.log(err));
                    },
                };
            case 'unavailable':
                return {
                    title: <ErrorIcon fill="var(--error-color)" />,
                    content: (
                        <div>
                            <p>We're currently unable to connect to this app.</p>
                            <p>Try again later.</p>
                        </div>
                    ),
                    width: '350px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const getLogo = (app, action, index) => {
        const name = app.name.substr(0, 1).toUpperCase() + app.name.substr(1, 1).toLowerCase();
        const logo = app.connection_dependencies.find((e) => e.field === 'LOGO')?.value;

        function getActionBtn() {
            if (action === 'add') {
                return (
                    <div className="app-status-icon">
                        <AddIcon fill="var(--font-color)" />
                    </div>
                );
            } else if (action === 'remove') {
                return (
                    <div className="app-status-icon">
                        <RemoveIcon fill="var(--error-color)" />
                    </div>
                );
            }
        }

        function content() {
            return (
                <>
                    {!logo && name}
                    {getActionBtn()}
                </>
            );
        }

        function div() {
            if (logo) {
                // The nested app-icon is necessary when using dangerouslySetInnerHTML because it
                // cannot have any children
                return (
                    <div className="app-icon">
                        <div className="app-icon" dangerouslySetInnerHTML={{ __html: logo }}></div>
                        {content()}
                    </div>
                );
            }

            return (
                <div
                    className="app-icon"
                    style={{
                        backgroundColor: appColors[index].bg,
                        color: appColors[index].font,
                    }}
                >
                    {content()}
                </div>
            );
        }

        return div();
    };

    return (
        <div className="connections-wrapper">
            <div className="section">
                <div className="header">New connection</div>
                <div className="app-select">
                    {loading && <Loader />}
                    {error && '=('}
                    {apps?.rows?.map((app, i) => {
                        if (!app.status === 'active') return;
                        return (
                            <div
                                className="app-select-item"
                                key={i}
                                onClick={() => {
                                    setValidationErrors(null);
                                    setNewConnectionApp(app);
                                    setNewConnectionName(null);
                                    setModalType('add-connection');
                                    setIsOpen(true);
                                }}
                            >
                                {getLogo(app, 'add', i)}
                                <p>{app.name}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="header">Existing Connections</div>
                <div className="app-select">
                    {apps?.rows?.map((app, i) => {
                        return app.connections.map((connection, j) => {
                            return (
                                <div
                                    className="app-select-item"
                                    key={`${i}_${j}`}
                                    onClick={() => {
                                        setRemoveConnectionApp(connection);
                                        setModalType('remove-connection');
                                        setIsOpen(true);
                                    }}
                                >
                                    {getLogo(app, 'remove', i)}
                                    <p>{connection.name}</p>
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default Connections;
