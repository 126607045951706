import './Database.css';

// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import { useQuery, useLazyQuery, GET_TABLES, GET_TABLES_COUNT } from 'utils/graphql';
import { ucWords } from 'utils/text.util';
import { canCreate, canUpdate } from 'utils/permission.util';

// Components
import Search from 'components/Search/Search';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';

function Database() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState();
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const { data: count, refetch: refreshCount } = useQuery(GET_TABLES_COUNT, {
        variables: { name: filter },
    });

    const [getTables] = useLazyQuery(GET_TABLES, {
        variables: {
            offset: 0,
            limit: perPage,
            name: '',
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
            refreshCount();
            setTableData(data);
            setColumns(getColumns());
            setLoading(false);
        },
    });

    const getColumns = () => [
        {
            name: 'Name',
            sortable: true,
            selector: (row) => row.name,
            format: (row) => ucWords(row.name),
        },
        {
            name: (() => {
                return (
                    canCreate(permissionSet) && (
                        <IconButton onClick={handleNewClick} padding="8px">
                            <AddIcon fill="var(--accent-color)" height="24" width="24" />
                        </IconButton>
                    )
                );
            })(),
            grow: 0,
            sortable: false,
            width: 'auto',
            selector: (row) =>
                canUpdate(permissionSet) && (
                    <IconButton
                        onClick={() => {
                            navigate(`/database/edit/${row.id}`);
                        }}
                        padding="8px"
                    >
                        <EditIcon fill="var(--font-color)" height="24" width="24" />
                    </IconButton>
                ),
        },
    ];

    const handleTableSelect = (row) => {
        navigate(`/database/${row.name}`);
    };

    const handleNewClick = () => {
        navigate(`/database/new`);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleSearch = useCallback(() => {
        setLoading(true);

        window.history.replaceState(
            null,
            '',
            `?query=${encodeURIComponent(filter)}&page=${page}&perPage=${perPage}`
        );

        getTables({
            variables: { name: `${filter}%`, offset: perPage * (page - 1), limit: perPage },
        });
    }, [getTables, filter, page, perPage]);

    /**
     * Update search if any of the dependencies change
     */
    useEffect(() => {
        handleSearch();
    }, [filter, page, perPage]);

    /**
     * Execute initial blank search if nothing in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        if (!query) {
            getTables();
        }
    }, []);

    /**
     * Execute search if in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const _page = parseInt(queryParams.get('page') || page);
        const _perPage = parseInt(queryParams.get('perPage') || perPage);

        setFilter(query);
        setPage(_page);
        setPerPage(_perPage);
    }, []);

    return (
        <div className="db">
            <div className="db-header">
                <Search handler={(search) => setFilter(search)} />
            </div>
            <DataTable
                columns={columns}
                data={tableData?.tableSchemaList.edges.map((row) => {
                    return row.node;
                })}
                noDataComponent="You don't have any objects."
                pagination={true}
                paginationServer={true}
                paginationPerPage={perPage}
                paginationDefaultPage={page}
                paginationTotalRows={count?.tableSchemaList?.totalCount || 0}
                progressPending={loading}
                progressComponent={<Loader />}
                defaultSortFieldId={1}
                highlightOnHover={true}
                onRowClicked={handleTableSelect}
                persistTableHead={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </div>
    );
}

export default Database;
