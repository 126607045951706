import './FieldSelection.css';

// Libraries
import { useEffect, useReducer, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    GET_SEGMENT_JOIN_TABLE,
    VALIDATE_SEGMENTATION_QUERY,
    useLazyQuery,
    useQuery,
} from 'utils/graphql';
import { v4 as uuidv4 } from 'uuid';

// Components
import DropArea from '../DropArea/DropArea';
import Loader from 'components/Loader/Loader';
import DraggablePill from 'components/DraggablePill/DraggablePill';
import IconButton from 'components/Buttons/IconButton/IconButton';
import CardList from 'components/CardList/CardList';
import Modal from 'components/Modal/Modal';
import Alert from 'components/Alert/Alert';
import SideMenu from 'components/SideMenu/SideMenu';
import Accordion from 'components/Accordion/Accordion';
import Tooltip from 'components/Tooltip/Tooltip';

// Utils
import { sortByProperty, sortByProperties } from 'utils/array.util';

// Assets
import { ReactComponent as MoreIcon } from 'assets/icons/more_vertical_icon2.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_24.svg';
import { ReactComponent as FunctionIcon } from 'assets/icons/function_icon_24.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star_icon_24.svg';

const sortPropertiesByPropertyNameWithIdHighestPriorioty = () => (a, b) => {
    const aProperty = a['property'];
    const bProperty = b['property'];
    if (aProperty === 'id' || aProperty < bProperty) {
        return -1;
    }
    if (bProperty === 'id' || aProperty > bProperty) {
        return 1;
    }
    return 0;
};

function FieldSelection({
    primaryTableId,
    handleSelection = () => {},
    requiredFields = [],
    initSelectedFields = [],
    segmentId,
    validate = false,
    setValidate,
    setIsValid,
}) {
    const [joinTables, setJoinTables] = useReducer((currentState, newState) => {
        // ordering the tables
        const orderedTables = newState.sort(sortByProperty('name'));
        // ordering the fields for each table
        for (let table of orderedTables) {
            table.properties = table.properties.sort(
                sortPropertiesByPropertyNameWithIdHighestPriorioty()
            );
        }
        return orderedTables;
    }, []);
    const [selectedFields, setSelectedFields] = useReducer((currentState, newState) => {
        return newState.sort(sortByProperties('table.name', 'property'));
    }, []);
    const [selectedReqFieldMap, setSelectedReqFieldMap] = useState([]);
    const [hasRequiredFields, setHasRequiredFields] = useState(false);
    const [canInit, setCanInit] = useState(false);
    const [modalOptions, setModalOptions] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFieldsError, setSelectedFieldsError] = useState('');
    const [selectFieldItems, setSelectFieldItems] = useState([]);

    const { loading } = useQuery(GET_SEGMENT_JOIN_TABLE, {
        fetchPolicy: 'no-cache',
        skip: !primaryTableId,
        variables: { tableId: primaryTableId },
        onCompleted: (data) => {
            setJoinTables(
                data.segmentationJoinTableList.map((joinTable) => {
                    const _joinTable = { ...joinTable };
                    _joinTable.properties = [
                        {
                            id: uuidv4(),
                            property: 'id',
                            table: { ...joinTable },
                            type: { type: 'UUID' },
                        },
                        ..._joinTable.properties,
                    ];
                    return _joinTable;
                })
            );
            setCanInit(true);
        },
    });

    const [validateSegmentation] = useLazyQuery(VALIDATE_SEGMENTATION_QUERY, {
        variables: {
            segmentationId: segmentId,
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const addSelectedField = (item) => {
        const _joinTables = [...joinTables];
        const _selectedFields = [...selectedFields];
        const table = _joinTables.find((joinTable) => joinTable.id === item.data.table.id);

        let mergedFields = _selectedFields;
        if (item.type === 'all_fields') {
            mergedFields = _selectedFields.concat(
                table.properties.map((property) => ({
                    ...property,
                    isMenuOpen: false,
                    aggregateFunction: null,
                }))
            );
            table.properties = [];
        } else {
            const propertyIndex = table.properties.findIndex((_prop) => _prop.id === item.data.id);
            const fieldToAdd = table.properties.splice(propertyIndex, 1);
            fieldToAdd[0].isMenuOpen = false;
            fieldToAdd[0].aggregateFunction = null;
            mergedFields = _selectedFields.concat(fieldToAdd);
        }

        setSelectedFields(mergedFields);
        setJoinTables(_joinTables);
    };

    const validateSelectedFields = async () => {
        setSelectedFieldsError('');
        // validating required fields
        if (requiredFields && requiredFields.length > 0) {
            let requiredFieldErrors = false;
            const _selectedReqFieldMap = [...selectedReqFieldMap];
            for (let requiredField of requiredFields) {
                const selectedReqField =
                    _selectedReqFieldMap.find((req) => req.requiredFieldId === requiredField.id) ||
                    {};
                if (!selectedReqField?.selectedField) {
                    selectedReqField.hasError = true;
                    requiredFieldErrors = true;
                }
            }
            if (requiredFieldErrors) {
                setSelectedReqFieldMap(_selectedReqFieldMap);
                setValidate(false);
                setSelectedFieldsError('The highlighted fields below are required.');
                return;
            }
        } else if (selectedFields.length === 0) {
            setSelectedFieldsError('Please select at least one field to proceed.');
            setValidate(false);
            return;
        }

        const aggregateFields = [];
        const regularFields = [];
        let errors = false;
        for (let field of selectedFields) {
            // resetting all selected field errors
            field.hasError = false;
            if (field.aggregateFunction) {
                aggregateFields.push(field);
            } else {
                regularFields.push(field);
            }
        }

        for (let aggregateField of aggregateFields) {
            for (let regularField of regularFields) {
                if (regularField.table.id === aggregateField.table.id) {
                    aggregateField.hasError = true;
                    regularField.hasError = true;
                    errors = true;
                }
            }
        }

        if (errors) {
            setSelectedFieldsError(
                'Please review the highlighted fields below. You cannot select an aggregate function field and an other field from the same table.'
            );
            setValidate(false);
            return;
        }

        const { data, error } = await validateSegmentation({
            variables: {
                columns: selectedFields.map((field) => ({
                    table: field.table.name,
                    property: field.property,
                    aggregateFunction: field.aggregateFunction,
                })),
            },
        });

        if (!data.segmentationQueryValidation.valid) {
            if (data.segmentationQueryValidation.error_columns) {
                for (let col of data.segmentationQueryValidation.error_columns) {
                    const errorField = selectedFields.find(
                        (field) => field.table.name === col.table && field.property === col.property
                    );
                    errorField.hasError = true;
                }
                setSelectedFieldsError(
                    'Please review the highlighted fields below. You cannot select a field from a table that already has an aggregate filter from the same table in the segmenation.'
                );
            } else {
                setModalOptions({
                    type: 'confirmation',
                    title: <ErrorIconBig fill="var(--error-color)" />,
                    content:
                        'With these selected table properties, the results do not match the ' +
                        'results when running the segmentation query. Are you sure you want to continue?',
                    confirmBtnText: 'Continue',
                    cancelBtnText: 'Cancel',
                    width: '350px',
                    textAlign: 'center',
                    confirmationBtnAction: () => setIsValid(true),
                });
                setIsOpen(true);
            }
            setValidate(false);
            return;
        }
        setIsValid(true);
    };

    const removeRequiredSelectedField = (requiredFieldId, selectedField) => {
        const _selectedReqFieldMap = [...selectedReqFieldMap];
        const _selectedReqField = _selectedReqFieldMap.find(
            (f) => f.requiredFieldId === requiredFieldId
        );
        _selectedReqField.selectedField = null;
        setSelectedReqFieldMap(_selectedReqFieldMap);
        removeSelectedField(selectedField);
    };

    const removeSelectedField = (item) => {
        // resetting the field error
        item.hasError = false;
        const _selectedFields = [...selectedFields];
        const index = _selectedFields.findIndex((f) => item.id === f.id);
        _selectedFields.splice(index, 1);
        setSelectedFields(_selectedFields);
        if (!hasRequiredFields && _selectedFields.length === 0) {
            setSelectedFieldsError('');
        }

        const _joinTables = [...joinTables];
        const table = _joinTables.find((joinTable) => joinTable.id === item.table.id);
        table.properties.push(item);
        setJoinTables(_joinTables);
    };

    const removeAllSelectedFields = () => {
        // resetting all selected field errors
        const _selectedFields = selectedFields.map((selectedField) => ({
            ...selectedField,
            hasError: false,
        }));

        const _joinTables = [...joinTables];

        for (let selectedField of _selectedFields) {
            const table = _joinTables.find((joinTable) => joinTable.id === selectedField.table.id);
            table.properties.push(selectedField);
        }

        setJoinTables(_joinTables);
        setSelectedFields([]);
        setSelectedFieldsError('');
    };

    const SelectedPillComponent = (
        selectedFieldIndex,
        removeFieldFunc = () => {},
        showMoreBtn = false
    ) => {
        const field = selectedFields[selectedFieldIndex];
        let propertyName = field.property;
        if (field.property === 'id') {
            propertyName = 'id (CDP id)';
        } else if (field.aggregateFunction) {
            propertyName = `${field.aggregateFunction}(${field.property})`;
        }
        return (
            <div key={selectedFieldIndex} className="selected-pill-wrapper">
                <div className={`selected-pill ${field.hasError ? 'selected-pill-error' : ''}`}>
                    <div className="selected-pill-icon" onClick={removeFieldFunc}>
                        <CloseIcon height={18} width={18} fill="#fff" />
                    </div>
                    <div className="selected-pill-text">
                        <span className="selected-pill-table">
                            <small>{field.table.name}</small>
                        </span>
                        <span className="selected-pill-property">{propertyName}</span>
                    </div>
                    {field.type.type === 'Number' && (
                        <>
                            <div className="selected-pill-function">
                                <IconButton
                                    padding="5px"
                                    onClick={() => showAggregateFunctionMenu(selectedFieldIndex)}
                                >
                                    <FunctionIcon height={20} width={20} fill="#fff" />
                                </IconButton>
                            </div>
                            <CardList
                                items={['AVG', 'MAX', 'MIN', 'SUM']}
                                onClickOutside={() =>
                                    showAggregateFunctionMenu(selectedFieldIndex, false)
                                }
                                onClickItemCallback={(item) =>
                                    setAggregateFunction(selectedFieldIndex, item)
                                }
                                getCardItemContent={(item) => item}
                                show={field.isMenuOpen}
                                width="auto"
                                top="40px"
                                right="10px"
                                showDivider="all"
                            />
                        </>
                    )}
                </div>
                {showMoreBtn && (
                    <div className="selected-pill-more">
                        <MoreIcon fill="var(--dark-grey-color)" />
                    </div>
                )}
            </div>
        );
    };

    const showAggregateFunctionMenu = (index, show = true) => {
        setSelectedFields(
            selectedFields.map((field, i) => (i === index ? { ...field, isMenuOpen: show } : field))
        );
    };

    const setAggregateFunction = (index, func) => {
        // setting the aggregate function on the selected field and closing menu dropdown
        setSelectedFields(
            selectedFields.map((field, i) =>
                i === index
                    ? { ...field, aggregateFunction: func, isMenuOpen: false, hasError: false }
                    : field
            )
        );
    };

    useEffect(() => {
        if (!requiredFields || requiredFields.length === 0) return;
        setHasRequiredFields(true);
    }, [requiredFields]);

    /**
     * Callback to return selected fields to parent.
     */
    useEffect(() => {
        if (typeof handleSelection !== 'function') return;
        handleSelection(
            selectedFields.map((f) => {
                const req = selectedReqFieldMap.find((req) => req.selectedField.id === f.id);
                return { ...f, requiredFieldId: req?.requiredFieldId };
            })
        );
    }, [selectedFields]);

    /**
     * On first load if 'initSelectedFields' has been passed.
     */
    useEffect(() => {
        // Everything needed has loaded
        if (!canInit) return;
        // Tables have loaded
        if (joinTables?.length < 1) return;
        // Prevent executing twice
        if (selectedFields?.length > 0) return;

        // When app has required fields,
        // re-create map of dependencies to selected fields.
        if (requiredFields.length > 0) {
            const _selectedReqFieldMap = requiredFields.reduce((res, req) => {
                const selectedField = initSelectedFields.find((f) => f.requiredFieldId === req.id);
                const item = {
                    requiredFieldId: req.id,
                    selectedField: selectedField ? selectedField : null,
                    hasError: false,
                };
                res.push(item);
                return res;
            }, []);
            setSelectedReqFieldMap(_selectedReqFieldMap);
        }

        // When an initial selection has been passed
        // setting selected fields and updating join table properties
        if (initSelectedFields?.length > 0) {
            const _joinTables = [...joinTables];
            let _selectedFields = [];

            initSelectedFields.forEach((_field) => {
                const table = _joinTables.find((joinTable) => joinTable.id === _field.table.id);
                const index = table.properties.findIndex((_prop) => _prop.id === _field.id);
                const fieldsToAdd = table.properties.splice(index, 1);
                _selectedFields = _selectedFields.concat(fieldsToAdd);
            });

            setSelectedFields(_selectedFields);
            setJoinTables(_joinTables);
        }

        setCanInit(false);
    }, [canInit, selectedFields]);

    useEffect(() => {
        if (!validate) return;
        validateSelectedFields();
    }, [validate]);

    useEffect(() => {
        if (!joinTables) return;
        const _items = [];
        for (let table of joinTables) {
            _items.push({
                header: (
                    <div className="fields-table-header">
                        {table.name}{' '}
                        {table.global_id === primaryTableId && (
                            <Tooltip tip="Primary table." width="150px" position="right-center">
                                <StarIcon fill="var(--accent-color)" />
                            </Tooltip>
                        )}
                    </div>
                ),
                body: (
                    <>
                        <DraggablePill
                            index={0}
                            type="all_fields"
                            data={{ ...table, table: { id: table.id } }}
                            text="All Fields"
                        />
                        {table.properties.map((property, i) => {
                            const pillText =
                                property.property === 'id' ? (
                                    <>
                                        id <span className="cdp-id">(CDP id)</span>
                                    </>
                                ) : (
                                    property.property
                                );
                            return (
                                <DraggablePill
                                    key={i}
                                    index={i + 1}
                                    type="field"
                                    data={property}
                                    text={pillText}
                                    dependencies={[property]}
                                />
                            );
                        })}
                    </>
                ),
            });
        }

        setSelectFieldItems(_items);
    }, [joinTables]);

    return (
        <div className="fieldselection">
            <DndProvider backend={HTML5Backend}>
                <SideMenu header={'Fields'} className="fields-col">
                    {loading ? <Loader /> : <Accordion items={selectFieldItems} padding="10px" />}
                </SideMenu>
                <div className="selected-fields-col">
                    {selectedFieldsError && (
                        <Alert variant="error" width="100%">
                            {selectedFieldsError}
                        </Alert>
                    )}
                    {hasRequiredFields ? (
                        <div className="required-fields">
                            {requiredFields.map((requiredField, i) => {
                                const selectedReqField = selectedReqFieldMap.find(
                                    (req) => req.requiredFieldId === requiredField.id
                                );
                                const selectedFieldIndex = selectedFields.findIndex((f) => {
                                    return f.id === selectedReqField?.selectedField.id;
                                });

                                return (
                                    <div key={i} className="required-field-row">
                                        <p>{requiredField.name}</p>
                                        {selectedFieldIndex >= 0 ? (
                                            SelectedPillComponent(
                                                selectedFieldIndex,
                                                () =>
                                                    removeRequiredSelectedField(
                                                        selectedReqField.requiredFieldId,
                                                        selectedFields[selectedFieldIndex]
                                                    ),
                                                true
                                            )
                                        ) : (
                                            <div
                                                key={i}
                                                className={`required-field-pill ${
                                                    selectedReqField?.hasError
                                                        ? 'required-pill-error'
                                                        : ''
                                                }`}
                                            >
                                                <DropArea
                                                    title="field-selection-da"
                                                    type={['field']}
                                                    dropHandler={(item) => {
                                                        const _selectedReqFieldMap = [
                                                            ...selectedReqFieldMap,
                                                        ];
                                                        const _selectedReqField =
                                                            _selectedReqFieldMap.find(
                                                                (req) =>
                                                                    req.requiredFieldId ===
                                                                    requiredField.id
                                                            );
                                                        _selectedReqField.selectedField = {
                                                            id: item.data.id,
                                                        };
                                                        _selectedReqField.hasError = false;

                                                        setSelectedReqFieldMap(
                                                            _selectedReqFieldMap
                                                        );
                                                        addSelectedField(item);
                                                    }}
                                                ></DropArea>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="selected-fields">
                            <DropArea
                                className="selected-fields-drop-area"
                                type={['field', 'all_fields']}
                                dropHandler={(item) => {
                                    addSelectedField(item);
                                }}
                            >
                                <div className="selections-row">
                                    <div className="selections-col">
                                        {selectedFields.map((field, i) =>
                                            SelectedPillComponent(i, () =>
                                                removeSelectedField(field)
                                            )
                                        )}
                                    </div>
                                    {selectedFields.length > 1 && (
                                        <div className="selections-col">
                                            <div className="remove-selections-wrapper">
                                                <IconButton
                                                    onClick={removeAllSelectedFields}
                                                    tooltip="Remove all properties"
                                                    tooltipWidth="100px"
                                                    tooltipPosition="right-center"
                                                >
                                                    <CloseIcon
                                                        width={24}
                                                        height={24}
                                                        fill="var(--error-color)"
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </DropArea>
                        </div>
                    )}
                </div>
            </DndProvider>
            <Modal options={modalOptions} isOpen={isOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default FieldSelection;
