import './Tooltip.css';

// Libraries
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

function Tooltip({
    children,
    tip,
    position = 'bottom-center',
    width = 'auto',
    isDisabled = false,
}) {
    const [tooltipPosition, setTooltipPosition] = useState({});
    const childrenRef = useRef(null);
    const tipRef = useRef(null);

    const onHover = () => {
        if (!childrenRef.current || !tipRef.current) return;
        const childrenBoundingRect = childrenRef.current.getBoundingClientRect();
        const tipBoundingRect = tipRef.current.getBoundingClientRect();
        switch (position) {
            case 'right-center':
                setTooltipPosition({
                    top: childrenBoundingRect.y + childrenBoundingRect.height / 2,
                    left: childrenBoundingRect.x + childrenBoundingRect.width + 10,
                });
                break;
            case 'left-center':
                setTooltipPosition({
                    top: childrenBoundingRect.y + childrenBoundingRect.height / 2,
                    left: childrenBoundingRect.x - tipBoundingRect.width - 10,
                });
                break;
            case 'bottom-center':
                setTooltipPosition({
                    top: childrenBoundingRect.y + childrenBoundingRect.height + 10,
                    left: childrenBoundingRect.x + childrenBoundingRect.width / 2,
                });
                break;
            case 'top-center':
                setTooltipPosition({
                    top: childrenBoundingRect.y - tipBoundingRect.height - 10,
                    left: childrenBoundingRect.x + childrenBoundingRect.width / 2,
                });
                break;
        }
    };

    return (
        <div className="tooltip" onMouseEnter={onHover}>
            <div className="tooltip-child" ref={childrenRef}>
                {children}
            </div>
            {!isDisabled && (
                <span
                    ref={tipRef}
                    className={`tooltip-text ${position}`}
                    style={{ width, ...tooltipPosition }}
                >
                    {tip}
                </span>
            )}
        </div>
    );
}

Tooltip.propTypes = {
    children: PropTypes.node,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    position: PropTypes.oneOf(['bottom-center', 'top-center', 'left-center', 'right-center']),
    width: PropTypes.string,
};

export default Tooltip;
