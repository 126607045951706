import './Visualization.css';

// Libraries
import { useEffect, useState, useRef } from 'react';
import { GET_KPIS_FOR_TABLE, previewSegment, useQuery } from 'utils/graphql';

// Components
import BarChart from 'components/SegmentationCharts/BarChart/BarChart';
import PieChart from 'components/SegmentationCharts/PieChart/PieChart';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import Loader from 'components/Loader/Loader';
import BubbleChart from 'components/SegmentationCharts/BubbleChart/BubbleChart';
import MatrixChart from 'components/SegmentationCharts/MatrixChart/MatrixChart';
import SingleValue from 'components/SegmentationCharts/SingleValue/SingleValue';
import Modal from 'components/Modal/Modal';
import { default as LineChartData } from 'components/SegmentationCharts/models/lineChartData';

// Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

// Variables
import { visualTypes } from 'views/Kpis/KpiBuilder/definitions';

// Utils & hooks
import { accentColorRgb, convertToRgbaString, generateSimilarColors } from 'utils/color.util';
import useScreenResize from 'hooks/useScreenResize';

const Visualization = ({
    name,
    segmentType,
    primaryTableId,
    includeFilterGroup,
    excludeFilterGroup,
    consent,
}) => {
    const [kpis, setKpis] = useState(['']);
    const [kpi, setKpi] = useState('');
    const [isLoadingChart, setIsLoadingChart] = useState(false);

    const [chart, setChart] = useState(null);
    const [chartType, setChartType] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [noCompatibleKpis, setNoCompatibleKpis] = useState(false);
    const [hasNoResults, setHasNoResults] = useState(false);
    const [previewObservable, setPreviewObservable] = useState();
    const chartRef = useRef(null);
    const chartContainerRef = useScreenResize(() => {
        chartRef.current && chartRef.current.resize();
    });

    const { loading: isLoadingKpis } = useQuery(GET_KPIS_FOR_TABLE, {
        variables: {
            id: primaryTableId,
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        skip: !primaryTableId,
        onCompleted: (results) => {
            const kpis = results.kpiListForTable;
            setKpis(kpis);
            if (kpis.length > 0) {
                setKpi(kpis[0].id);
            } else {
                setNoCompatibleKpis(true);
            }
        },
    });

    const abortLatestPreviewSegmentQuery = () =>
        previewObservable && previewObservable.unsubscribe();

    const loadKpiPreview = async () => {
        setIsLoadingChart(true);
        setHasNoResults(false);
        const segmentation = {
            name: name,
            isConsent: segmentType === 'consent' ? true : false,
            primaryTableId: primaryTableId,
            includeFilterGroup: includeFilterGroup,
            excludeFilterGroup: excludeFilterGroup,
            consentId: consent ? consent : null,
            kpi: kpi,
        };

        const previewSegmentObservable = previewSegment(segmentation, {
            fetchPolicy: 'network-only',
        }).subscribe((response) => {
            if (response.errors) {
                openModal('loadingError');
            } else {
                const previewData = response.data.segmentationPreview;

                if (previewData.results.length === 0) {
                    setHasNoResults(true);
                    setIsLoadingChart(false);
                    return;
                }

                const xAxis = previewData.data.x_axis;
                const yAxis = previewData.data.y_axis;
                const zAxis = previewData.data.z_axis;
                const title = previewData.name;
                const _chartType = previewData.graph_type;
                setChartType(_chartType);
                if (_chartType === visualTypes.BAR) {
                    setChart(
                        <BarChart
                            innerRef={chartRef}
                            title={title}
                            data={
                                new LineChartData(
                                    previewData.results.map((result) => result[xAxis.alias]),
                                    [
                                        {
                                            label: yAxis.alias,
                                            data: previewData.results.map(
                                                (result) => result[yAxis.alias]
                                            ),
                                            borderColor: convertToRgbaString(accentColorRgb),
                                            backgroundColor: convertToRgbaString(
                                                accentColorRgb,
                                                0.8
                                            ),
                                        },
                                    ]
                                )
                            }
                            xAxisTitle={xAxis.alias}
                            yAxisTitle={yAxis.alias}
                        />
                    );
                } else if (_chartType === visualTypes.GROUPED_BAR) {
                    const groupByLabels = [];
                    const resultsDictionary = {};
                    const groupBy = previewData.data.grouped_by;
                    for (const result of previewData.results) {
                        const groupByLabel = result[groupBy.alias];
                        const label = result[xAxis.alias];
                        if (resultsDictionary[label]) {
                            resultsDictionary[label][groupByLabel] = result[yAxis.alias];
                        } else {
                            resultsDictionary[label] = { [groupByLabel]: result[yAxis.alias] };
                        }
                        if (!groupByLabels.includes(groupByLabel)) {
                            groupByLabels.push(groupByLabel);
                        }
                    }
                    const colors = generateSimilarColors(groupByLabels.length);
                    setChart(
                        <BarChart
                            innerRef={chartRef}
                            title={title}
                            data={
                                new LineChartData(
                                    Object.keys(resultsDictionary),
                                    groupByLabels.map((label, i) => {
                                        const data = [];
                                        for (let resultKey in resultsDictionary) {
                                            let resultValue = resultsDictionary[resultKey];
                                            data.push(resultValue[label] || 0);
                                        }
                                        return {
                                            label: label,
                                            data: data,
                                            borderColor: convertToRgbaString(accentColorRgb),
                                            backgroundColor: colors[i],
                                        };
                                    })
                                )
                            }
                            xAxisTitle={xAxis.alias}
                            yAxisTitle={yAxis.alias}
                        />
                    );
                } else if (_chartType === visualTypes.PIE) {
                    const data = [];
                    const labels = [];
                    const bgColors = [];
                    const borderColors = [];
                    const colors = generateSimilarColors(previewData.results.length);
                    for (let i = 0; i < previewData.results.length; i++) {
                        const result = previewData.results[i];
                        labels.push(result[xAxis.alias]);
                        data.push(result[yAxis.alias]);
                        bgColors.push(colors[i]);
                        borderColors.push(convertToRgbaString(accentColorRgb));
                    }
                    setChart(
                        <PieChart
                            innerRef={chartRef}
                            title={title}
                            data={{
                                labels,
                                datasets: [
                                    {
                                        label: yAxis.alias,
                                        data,
                                        backgroundColor: bgColors,
                                        borderColor: borderColors,
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                        />
                    );
                } else if (_chartType === visualTypes.BUBBLE) {
                    const bubbleData = [];
                    for (let result of previewData.results) {
                        const existingBubble = bubbleData.find(
                            (bubble) =>
                                bubble.x === result[xAxis.alias] && bubble.y === result[yAxis.alias]
                        );
                        if (existingBubble) {
                            existingBubble.r =
                                parseInt(existingBubble.r) + parseInt(result[zAxis.alias]);
                        } else {
                            bubbleData.push({
                                x: result[xAxis.alias],
                                y: result[yAxis.alias],
                                r: result[zAxis.alias],
                            });
                        }
                    }
                    setChart(
                        <BubbleChart
                            innerRef={chartRef}
                            title={title}
                            datasets={[
                                {
                                    label: zAxis.alias,
                                    data: bubbleData,
                                    borderColor: convertToRgbaString(accentColorRgb),
                                    backgroundColor: convertToRgbaString(accentColorRgb, 0.8),
                                },
                            ]}
                            xAxisTitle={xAxis.alias}
                            yAxisTitle={yAxis.alias}
                            zAxisTitle={zAxis.alias}
                        />
                    );
                } else if (_chartType === visualTypes.MATRIX) {
                    const data = [];
                    for (let result of previewData.results) {
                        const existingDataPoint = data.find(
                            (data) =>
                                data.x === result[xAxis.alias] && data.y === result[yAxis.alias]
                        );
                        if (existingDataPoint) {
                            existingDataPoint.z =
                                parseInt(existingDataPoint.z) + parseInt(result[zAxis.alias]);
                        } else {
                            data.push({
                                x: result[xAxis.alias],
                                y: result[yAxis.alias],
                                z: result[zAxis.alias],
                            });
                        }
                    }
                    const xAxisLabels = Array.from({ length: xAxis.ntile }, (_, i) =>
                        (i + 1).toString()
                    );
                    const yAxisLabels = Array.from({ length: yAxis.ntile }, (_, i) =>
                        (i + 1).toString()
                    );
                    setChart(
                        <MatrixChart
                            innerRef={chartRef}
                            title={title}
                            datasets={[
                                {
                                    data,
                                },
                            ]}
                            xAxisTitle={xAxis.alias}
                            yAxisTitle={yAxis.alias}
                            zAxisTitle={zAxis.alias}
                            xAxisLabels={xAxisLabels}
                            yAxisLabels={yAxisLabels}
                            backgroundRgbBaseColor={accentColorRgb}
                        />
                    );
                } else if (_chartType === visualTypes.SINGLE_VALUE) {
                    const numerator = previewData.results[0][xAxis.alias];
                    const denominator = yAxis ? previewData.results[0][yAxis.alias] : 1;
                    setChart(
                        <SingleValue
                            title={title}
                            numerator={parseFloat(numerator)}
                            denominator={parseFloat(denominator)}
                            displayType={previewData.data.displayType.displayAs}
                        />
                    );
                }
            }
            setIsLoadingChart(false);
        });
        setPreviewObservable(previewSegmentObservable);
    };

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'loadingError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error loading the {segmentType} results.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    useEffect(() => {
        if (!kpi) return;
        abortLatestPreviewSegmentQuery();
        loadKpiPreview();
    }, [kpi]);

    return (
        <div className="visualization">
            {isLoadingKpis ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : noCompatibleKpis ? (
                <h2 className="header">This {segmentType} has no compatible KPIs to display.</h2>
            ) : (
                <>
                    <h2 className="header">Select KPI to Visualize</h2>
                    <div
                        style={{
                            marginTop: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Dropdown
                            width="300px"
                            value={kpi}
                            values={kpis.map((kpi) => {
                                return {
                                    text: kpi.name,
                                    value: kpi.id,
                                };
                            })}
                            setValue={(e) => {
                                setKpi(e.target.value);
                            }}
                        />
                    </div>
                </>
            )}
            {isLoadingChart ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : hasNoResults ? (
                <h3>This {segmentType} has no results to display.</h3>
            ) : (
                <div
                    ref={chartContainerRef}
                    className="chart"
                    style={{ maxWidth: chartType === 'PIE' ? '750px' : '100%' }}
                >
                    {chart}
                </div>
            )}
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
};

export default Visualization;
