import './Table.css';

// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// Utils
import { useQuery, GET_TABLE, GET_TABLES_AND_PROPERTIES, useLazyQuery } from 'utils/graphql';
import { ucWords } from 'utils/text.util';
import { hasPermissionFor, canUpdate } from 'utils/permission.util';

// Components
import Search from 'components/Search/Search';
import Loader from 'components/Loader/Loader';
import DataTable from 'components/DataTable/DataTable';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Assets
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';

function Table() {
    let { table } = useParams();
    let navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState();
    const [filter, setFilter] = useState('');
    const [columns, setColumns] = useState([]);
    const [columnProperties, setColumnProperties] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const { permissionSet } = useContext(CurrentPermissionContext);

    const updateTable = (results) => {
        if (results.table.count > 0) {
            const keys = Object.keys(results.table.records[0][table]);
            const encryptedColumns = columnProperties
                .filter((_p) => _p.encrypt)
                .map((_p) => _p.property);
            setColumns(
                keys.map((key) => {
                    return {
                        name: key,
                        reorder: true,
                        searchable: false,
                        selector: (row) => {
                            if (
                                encryptedColumns.includes(key) &&
                                !hasPermissionFor(permissionSet, 'database.encrypted', 'read')
                            ) {
                                return <div style={{ filter: 'blur(3px)' }}>encrypted</div>;
                            }
                            return row[key]?.toString();
                        },
                    };
                })
            );
        } else {
            setColumns(
                columnProperties.map((c) => {
                    return {
                        name: c.property,
                        reorder: true,
                        searchable: false,
                    };
                })
            );
        }
        setTotalRows(results.table.count);
    };

    const [getTableData, { data, loading: table_loading }] = useLazyQuery(GET_TABLE, {
        fetchPolicy: 'network-only',
        skip: loading,
        variables: {
            name: table,
            offset: 0,
            limit: perPage,
        },
        onCompleted: (results) => {
            updateTable(results);
            setLoading(false);
        },
        onError: (error) => {
            console.log(error);
            setLoading(false);
        },
    });

    useQuery(GET_TABLES_AND_PROPERTIES, {
        skip: !table,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        variables: { name: table },
        onCompleted: (results) => {
            const _columnProperties =
                results?.tableSchemaList?.edges?.[0]?.node?.properties.edges.map((row) => {
                    return {
                        property: row.node.property,
                        type: row.node.type.type,
                        encrypt: row.node.encrypt,
                    };
                }) ?? [];
            setColumnProperties(_columnProperties);
            setId(results?.tableSchemaList?.edges?.[0]?.node?.id);
        },
    });

    const buildSearchFilters = (search) => {
        let filters = [];
        if (search) {
            const isUUID = search?.replaceAll('-', '').length === 32;

            for (const property of columnProperties) {
                if (['Text'].includes(property?.type)) {
                    filters.push({
                        left: property.property,
                        right: `%${search}%`,
                        expression: 'iLIKE',
                    });
                } else if (property?.type === 'UUID' && isUUID) {
                    filters.push({
                        left: property.property,
                        right: search,
                        expression: '=',
                    });
                }
            }

            if (isUUID) {
                filters.push({
                    left: 'id',
                    right: search,
                    expression: '=',
                });
            }

            // Hack to get no results back when no filters
            if (filters.length === 0) {
                filters = [{ and_: [{ left: 'id', right: 'null', expression: '=' }] }];
            } else {
                filters = [{ or_: filters }];
            }
        }

        return filters;
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    /**
     * handleSearch:
     * Only allow search by Text or UUID types.
     */
    const handleSearch = useCallback(() => {
        setLoading(true);

        window.history.replaceState(
            null,
            '',
            `?query=${encodeURIComponent(filter)}&page=${page}&perPage=${perPage}`
        );

        // Assemble graph query filter
        const filters = buildSearchFilters(filter);

        getTableData({
            variables: { filter: filters, offset: perPage * (page - 1), limit: perPage },
        });
    }, [columnProperties, filter, page, perPage]);

    /**
     * Execute initial blank search if nothing in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        if (!query) {
            getTableData();
        }
    }, []);

    /**
     * Update search if any of the dependencies change
     */
    useEffect(() => {
        if (columnProperties?.length === 0) return;
        handleSearch();
    }, [columnProperties, filter, page, perPage]);

    /**
     * Execute search if in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const _page = queryParams.get('page') || page;
        const _perPage = queryParams.get('perPage') || perPage;

        setFilter(query);
        setPage(_page);
        setPerPage(_perPage);
    }, []);

    useEffect(() => {
        if (table_loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [table_loading]);

    return (
        <div className="table">
            <div className="table-header">
                <p>{ucWords(table)}</p>
                <div>
                    <Search handler={(search) => setFilter(search)} />
                </div>
                <div className="header-icons">
                    {canUpdate(permissionSet) && (
                        <IconButton
                            onClick={() => {
                                navigate(`/database/edit/${id}`);
                            }}
                            padding="8px"
                        >
                            <EditIcon fill="var(--font-color)" height="24" width="24" />
                        </IconButton>
                    )}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data?.table.records.map((row) => row[table])}
                noDataComponent="Your object has no data."
                pagination={true}
                paginationServer={true}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading}
                progressComponent={<Loader />}
                defaultSortFieldId={2}
                highlightOnHover={true}
            />
        </div>
    );
}

export default Table;
