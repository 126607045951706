import './SideMenu.css';

const SideMenu = ({ children, header, width = '300px', className = '' }) => {
    return (
        <div className={`side-menu ${className}`} style={{ width }}>
            <div className="side-menu-header">{header}</div>
            <div className="side-menu-body">{children}</div>
        </div>
    );
};

export default SideMenu;
