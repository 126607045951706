import './AccessWrapper.css';

// Libraries
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PermissionsContext, RoutesList } from 'App';

// Components
import Loader from 'components/Loader/Loader';
import ContentPanel from 'components/ContentPanel/ContentPanel';

// Assets
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-triangle.svg';

export const CurrentPermissionContext = createContext();

/**
 * Component to check if user has permission to view page.
 */
const AccessWrapper = ({ children }) => {
    const location = useLocation();
    const { permissions } = useContext(PermissionsContext);
    const [lastLocation, setLastLocation] = useState();
    const [permissionSet, setPermissionSet] = useState();
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);

    const getPermissionStatus = () => {
        let status;
        if (permissionDenied) {
            status = (
                <>
                    <WarningIcon fill="var(--error-color)" width="40" height="40" />
                    <p>Permission Denied</p>
                </>
            );
        } else {
            status = <Loader />;
        }

        return (
            <ContentPanel>
                <div className="permissions-loader">{status}</div>
            </ContentPanel>
        );
    };

    /**
     * Match current path with a Router path and return
     * formatted string to compare against db entries.
     *
     * @returns String formattedPath
     */
    const getFormattedRouterPath = () => {
        // Break down path into an array
        let path = location.pathname.substring(1).replace(/\/$/, '').split('/');

        // In case no match but this should never happen
        let formattedPath = path.join('.');
        // Catch-all for all App connections
        if (formattedPath.includes('settings.connections.')) {
            formattedPath = 'settings.connections.*';
        }

        // Match the current path with the router path while considering whildcards (:var)
        RoutesList.forEach((route) => {
            const routeParts = route.path.substring(1).split('/');
            if (routeParts.length === path.length) {
                let match = true;
                for (let index = 0; index < routeParts.length; index++) {
                    if (routeParts[index].includes(':') && !['new', 'edit'].includes(path[index]))
                        continue;
                    if (routeParts[index] !== path[index]) {
                        return (match = false);
                    }
                }

                if (match) {
                    return (formattedPath = routeParts
                        .map((part) => {
                            if (part.includes(':')) return '*';
                            return part;
                        })
                        .join('.'));
                }
            }
        });

        return formattedPath;
    };

    /**
     * Verify if we've switched to a new page.
     * This avoids flickering while changing page because of
     * not having reverted 'hasPermission' back to false yet.
     *
     * Ex: if you're coming from a page where you had permission,
     * 'hasPermission' is briefly still true until re-render.
     *
     * @returns boolean isNewPage
     */
    const componentIsReady = () => {
        if (location.key !== lastLocation.key) return true;
    };

    /**
     * Reset permissions when new route.
     *
     * Prevent nested settings page from checking for permissions.
     * We consider the nested routes to be of the same permission
     * level as settings until further notice.
     */
    useEffect(() => {
        const path = location.pathname.substring(1).replace(/\/$/, '').split('/');
        const isNestSettingsPage = path?.[0] === 'settings' && path.length > 1;
        if (!isNestSettingsPage) {
            setHasPermission(false);
            setPermissionDenied(false);
        }
        setLastLocation(location);
    }, [location]);

    /**
     * When user navigates to new route, check if they
     * have permission against the backend.
     */
    useEffect(() => {
        if (!permissions) return;

        let path = getFormattedRouterPath();

        const permission = permissions.find((p) => p.object === path && p.action === 'read');

        if (permission) {
            // Putting a timeout otherwise it just looks like a flicker
            setTimeout(() => {
                setHasPermission(true);

                // Current permission level.
                // Contains all permissions set (CRUD) if available.
                // Available as Context to all sub components.
                const permissionSet = permissions.filter(
                    (p) => (p.object === path && p.type === 'page') || p.type === 'data'
                );
                setPermissionSet(permissionSet);
            }, 500);
        } else {
            setPermissionDenied(true);
        }
    }, [location, permissions]);

    return (
        <CurrentPermissionContext.Provider value={{ permissionSet }}>
            {hasPermission && !componentIsReady() ? children : getPermissionStatus()}
        </CurrentPermissionContext.Provider>
    );
};

export default AccessWrapper;
