import './Kpis.css';

// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import {
    GET_KPIS,
    GET_KPI_LIST_COUNT,
    DELETE_KPI,
    useQuery,
    useLazyQuery,
    useMutation,
} from 'utils/graphql';

// Components
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';
import Loader from 'components/Loader/Loader';
import DataTable from 'components/DataTable/DataTable';
import Search from 'components/Search/Search';
import Modal from 'components/Modal/Modal';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { canCreate, canDelete } from 'utils/permission.util';

const Kpis = () => {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    const location = useLocation();
    const [filter, setFilter] = useState('');
    const [kpiToDelete, setKpiToDelete] = useState();
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('name');
    const [orderByDirection, setOrderByDirection] = useState('ASC');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [tableData, setTableData] = useState();
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const { data: count, refetch: refreshCount } = useQuery(GET_KPI_LIST_COUNT, {
        variables: {
            search: filter,
            searchProperties: ['name'],
        },
    });

    const [getKpis] = useLazyQuery(GET_KPIS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            offset: offset,
            limit: rowsPerPage,
            orderBy: orderBy,
            orderByDirection: orderByDirection,
        },
        onCompleted: (data) => {
            refreshCount();
            setTableData(data);
            setColumns(getColumns());
            setLoading(false);
        },
    });

    const [deleteKpi] = useMutation(DELETE_KPI);

    const getColumns = () => [
        {
            id: 'name',
            name: 'Name',
            sortable: true,
            selector: (row) => row.name,
        },
        {
            id: 'graph',
            name: 'Graph',
            sortable: true,
            selector: (row) => row.graph,
        },
        {
            id: 'action_col',
            name: canCreate(permissionSet) && (
                <IconButton onClick={() => navigate('/kpis/new')} padding="8px">
                    <AddIcon fill="var(--accent-color)" height="24" width="24" />
                </IconButton>
            ),
            sortable: false,
            width: '152px',
            right: true,
            selector: (row) => {
                return (
                    canDelete(permissionSet) && (
                        <IconButton
                            onClick={() => {
                                setKpiToDelete(row);
                                openModal('delete');
                            }}
                            padding="8px"
                        >
                            <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                        </IconButton>
                    )
                );
            },
        },
    ];

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'delete':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>Are you sure you want to delete this KPI? </p>
                            <b>{kpiToDelete?.name}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '300px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {
                        deleteKpi({
                            variables: { id: kpiToDelete.id },
                            onCompleted: () => {
                                loadKpiList();
                                openModal('deleted');
                            },
                        });
                    },
                };
            case 'deleted':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>KPI has been deleted.</p>
                        </div>
                    ),
                    width: '300px',
                    textAlign: 'center',
                    confirmationBtnAction: () => setModalIsOpen(false),
                };
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleSearch = useCallback(() => {
        setLoading(true);

        window.history.replaceState(
            null,
            '',
            `?query=${encodeURIComponent(filter)}&page=${page}&perPage=${perPage}`
        );

        getKpis({
            variables: {
                search: filter,
                searchProperties: ['name'],
                offset: perPage * (page - 1),
                limit: perPage,
            },
        });
    }, [getKpis, filter, page, perPage]);

    const loadKpiList = () => {
        getKpis({
            offset: offset,
            limit: rowsPerPage,
            orderBy: orderBy,
            orderByDirection: orderByDirection,
            search: filter,
            searchProperties: ['name'],
        });
    };

    useEffect(() => {
        loadKpiList();
    }, [offset, rowsPerPage, orderBy, orderByDirection, filter]);

    /**
     * Execute initial blank search if nothing in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        if (!query) {
            loadKpiList();
        }
    }, []);

    /**
     * Update search if any of the dependencies change
     */
    useEffect(() => {
        handleSearch();
    }, [filter, page, perPage]);

    /**
     * Execute search if in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const _page = queryParams.get('page') || page;
        const _perPage = queryParams.get('perPage') || perPage;

        setFilter(query);
        setPage(_page);
        setPerPage(_perPage);
    }, []);

    return (
        <div className="kpis">
            <div className="kpis-header">
                <Search handler={(search) => setFilter(search)} />
            </div>
            <DataTable
                columns={columns}
                data={tableData?.kpiList.edges.map((row) => {
                    return row.node;
                })}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={count?.kpiList?.totalCount || 0}
                paginationPerPage={perPage}
                paginationDefaultPage={page}
                progressPending={loading}
                progressComponent={<Loader />}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
                defaultSortFieldId={1}
                highlightOnHover={true}
                sortServer
                onRowClicked={(row) => navigate(`/kpis/${row.id}`)}
                persistTableHead={true}
            />

            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setModalIsOpen} />
        </div>
    );
};

export default Kpis;
