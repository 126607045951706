import './Loader.css';

// Assets
import { ReactComponent as LogoIcon } from 'assets/okzou_icon.svg';

function Loader({ height = '50px', className = '' }) {
    return (
        <div className={`okzou-loader ${className}`}>
            <div>
                <LogoIcon height={height} width={height} />
            </div>
        </div>
    );
}

export default Loader;
